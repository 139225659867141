import { Button, ButtonGroup } from "@mui/material";
import { useMemo } from "react";
import { OrderByFilter } from "@@types/OrderByFilter";
import { Customer } from "@features/customer/types/Customer";

export type CustomersOrderBy = "name" | "invoicesChangedAt";

type FilterItemData = {
  label: string;
  sortBy: Required<OrderByFilter<Customer>>["sort"];
  order: Required<OrderByFilter<Customer>>["order"];
};

type Props = {
  sortBy: CustomersOrderBy;
  setOrderFilter: (orderFilter: Required<OrderByFilter<Customer>>) => void;
};

const TableFilterType = ({ sortBy, setOrderFilter }: Props) => {
  const filterList = useMemo<FilterItemData[]>(
    () => [
      { label: "Nome", sortBy: "name", order: "asc" },
      {
        label: "Ultimos Alterados",
        sortBy: "invoicesChangedAt",
        order: "desc",
      },
    ],
    []
  );

  return (
    <ButtonGroup variant="outlined" size="small">
      {filterList.map((filterItem, index) => {
        const key = `button-${filterItem.sortBy}-${index}`;
        const isSelected = sortBy === filterItem.sortBy;

        return (
          <Button
            key={key}
            variant={isSelected ? "contained" : "outlined"}
            disableElevation={isSelected}
            disableRipple={isSelected}
            sx={{ cursor: isSelected ? "default" : undefined }}
            onClick={() => {
              if (isSelected) return;

              setOrderFilter({
                sort: filterItem.sortBy,
                order: filterItem.order,
              });
            }}
          >
            {filterItem.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default TableFilterType;
