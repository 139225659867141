import { EncryptStorage } from "encrypt-storage";
import { SECRET_KEY } from "@data/configs";

function getEncryptStorage(): EncryptStorage {
  return new EncryptStorage(SECRET_KEY);
}

function getItem(key: string): string | null | undefined {
  const encryptStorage = getEncryptStorage();
  return encryptStorage.getItem(key);
}

function setItem(key: string, value: string): void {
  const encryptStorage = getEncryptStorage();
  encryptStorage.setItem(key, value);
}

function removeItem(key: string): void {
  const encryptStorage = getEncryptStorage();
  encryptStorage.removeItem(key);
}

const valueStorageEncrypted = {
  getItem,
  setItem,
  removeItem,
};

export default valueStorageEncrypted;
