import React from "react";
import { DialogActions, DialogContent } from "@mui/material";

type Props = {
  actions?: React.ReactNode;
  children: React.ReactNode;
};

const ModalContent = ({ children, actions }: Props) => {
  return (
    <>
      <DialogContent sx={{ overflowY: "auto" }}>{children}</DialogContent>
      <DialogActions
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "background.paper",
        }}
      >
        {actions}
      </DialogActions>
    </>
  );
};

export default ModalContent;
