import React from "react";
import ScreenContent from "@components/ui/ScreenContent";
import { Outlet } from "react-router-dom";
import MetricTab from "@features/metric/components/ui/MetricTab";

const MetricView = () => {
  return (
    <ScreenContent title="Métricas">
      <MetricTab />
      <Outlet />
    </ScreenContent>
  );
};

export default MetricView;
