import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  itemOpenState,
  useCurrentCustomer,
  useCustomerEditOpen,
} from "@features/customer";
import FullScreenModal from "@components/ui/FullScreenModal";
import InvoiceView from "@features/invoice/components/ui/InvoiceView";
import { Button, ButtonGroup, Divider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PaymentsIcon from "@mui/icons-material/Payments";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import BuyButton from "@features/invoice/components/ui/BuyButton";
import PayButton from "@features/invoice/components/ui/PayButton";
import { InvoiceType } from "@features/invoice/enums/InvoiceType";
import {
  invoiceCreateTypeState,
  itemSelectedState,
  modalIsOpenState,
} from "@features/invoice/states/atoms";

const InvoiceModal = () => {
  const customerEditOpen = useCustomerEditOpen();
  const customer = useCurrentCustomer();

  const [itemOpen, setItemOpen] = useRecoilState(itemOpenState);
  const setItemSelected = useSetRecoilState(itemSelectedState);
  const setInvoiceCreateType = useSetRecoilState(invoiceCreateTypeState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);

  const handleModalClose = () => {
    setItemOpen(undefined);
  };

  const modalTitle = `Cliente: ${itemOpen?.name}`;

  const handleCustomerEdit = () => {
    if (itemOpen) customerEditOpen(itemOpen);
  };

  const handleCreate = (invoiceType: InvoiceType) => () => {
    setInvoiceCreateType(invoiceType);
    setItemSelected(undefined);
    setModalIsOpen(true);
  };

  const customerCanBuy = customer?.canBuy ?? false;

  const buttonUpdateCustomer = (
    <>
      <Button
        color="secondary"
        variant="contained"
        endIcon={<EditIcon />}
        onClick={handleCustomerEdit}
      >
        Editar Cliente
      </Button>
      <Divider orientation="vertical" flexItem />
      <ButtonGroup variant="contained" color="success">
        <BuyButton
          endIcon={<ShoppingBagIcon />}
          onClick={handleCreate(InvoiceType.PURCHASE)}
          disabled={!customerCanBuy}
        >
          Compra
        </BuyButton>
        <PayButton
          endIcon={<PaymentsIcon />}
          onClick={handleCreate(InvoiceType.PAYMENT)}
        >
          Pagamento
        </PayButton>
      </ButtonGroup>
    </>
  );

  return (
    <FullScreenModal
      title={modalTitle}
      open={!!itemOpen}
      onClose={handleModalClose}
      actions={buttonUpdateCustomer}
    >
      <InvoiceView />
    </FullScreenModal>
  );
};

export default InvoiceModal;
