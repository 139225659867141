import { useSetRecoilState } from "recoil";
import {
  formIsLoadingState,
  lastChangeState,
  listState,
  modalIsOpenState,
} from "@features/invoice/states/atoms";
import { useSnackbar } from "@contexts/SnackbarContext";
import { PaginationData } from "@@types/PaginationData";
import axiosErrorToString from "@utils/axiosErrorToString";
import { InvoiceFormData } from "@features/invoice/types/InvoiceFormData";
import invoiceApi from "@features/invoice/services/invoiceApi";
import { Invoice } from "@features/invoice/types/Invoice";

type HookReturn = (invoiceId: string, invoiceFormData: InvoiceFormData) => void;

const useUpdateInvoice = (): HookReturn => {
  const setList = useSetRecoilState<PaginationData<Invoice>>(listState);
  const setFormIsLoading = useSetRecoilState(formIsLoadingState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);
  const setLastChange = useSetRecoilState(lastChangeState);
  const snackbar = useSnackbar();

  return async (invoiceId: string, invoiceFormData: InvoiceFormData) => {
    try {
      setFormIsLoading(true);

      const updatedInvoice = await invoiceApi.update(
        invoiceId,
        invoiceFormData
      );

      setList((oldList) => {
        const index = oldList.data.findIndex(
          (operador) => operador.id === invoiceId
        );

        const newOperadorList = [
          ...oldList.data.slice(0, index),
          updatedInvoice,
          ...oldList.data.slice(index + 1),
        ];

        return {
          ...oldList,
          data: newOperadorList,
        };
      });

      setLastChange(Date.now());
      setModalIsOpen(false);
      snackbar.show("Registro atualizado com sucesso");
    } catch (e) {
      snackbar.show(axiosErrorToString(e, "Erro desconhecido"));
    } finally {
      setFormIsLoading(false);
    }
  };
};

export default useUpdateInvoice;
