import { Box, Divider, Stack, Typography } from "@mui/material";
import InfoItem from "@features/invoice/components/ui/InfoItem";
import { blue, red, green } from "@mui/material/colors";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import useInvoiceInfo from "@features/invoice/hooks/useInvoiceInfo";
import { toCurrency } from "@utils/currencyUtils";
import pluralize from "pluralize";
import { useRef } from "react";
import EventIcon from "@mui/icons-material/Event";

const InvoiceInfo = () => {
  const { isLoading, isError, info } = useInvoiceInfo();
  const dataItemRef = useRef<HTMLDivElement>(null);

  if (isLoading) {
    return (
      <Stack
        width="100%"
        bgcolor="grey.100"
        alignItems="center"
        justifyContent="center"
        style={{ height: dataItemRef.current?.clientHeight }}
      >
        <Typography variant="subtitle1">Carregando Informações...</Typography>
      </Stack>
    );
  }

  if (isError) {
    return (
      <Stack
        width="100%"
        bgcolor="grey.100"
        alignItems="center"
        justifyContent="center"
        style={{ height: dataItemRef.current?.clientHeight }}
      >
        <Typography variant="subtitle1">
          Problema ao Carregar as Informações...
        </Typography>
      </Stack>
    );
  }

  const { remainingDebt, daysOfLastRegister } = info;

  const hasDebt = Number(remainingDebt) > 0;

  return (
    <Box width="100%" bgcolor="grey.100" ref={dataItemRef}>
      <Box py={2} px={2}>
        <Stack direction="row" spacing={4}>
          <InfoItem
            title="Dívida Restante"
            value={toCurrency(remainingDebt)}
            icon={<AttachMoneyIcon />}
            color={hasDebt ? red : green}
          />
          <InfoItem
            title="Dias do Ultimo Registro"
            value={pluralize("dia", daysOfLastRegister, true)}
            icon={<EventIcon />}
            color={blue}
          />
        </Stack>
      </Box>
      <Divider />
    </Box>
  );
};

export default InvoiceInfo;
