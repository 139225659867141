import { Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "@contexts/SnackbarContext";
import configurationApi from "@features/configuration/services/configurationApi";
import OnlyAdmin from "@components/ui/OnlyAdmin";

const ConfigurationView = () => {
  const snackbar = useSnackbar();

  const doSync = async () => {
    try {
      snackbar.show("Sincronização iniciada");

      await configurationApi.searchSync();

      snackbar.show("Sincronização finalizada");
    } catch (e) {
      snackbar.show("Erro ao sincronizar");
      console.error(e);
    }
  };

  return (
    <Stack direction="column" p={4}>
      <OnlyAdmin>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">
            Sincronizar Banco de Dados de Busca
          </Typography>
          <Button variant="contained" onClick={doSync}>
            Sincronizar
          </Button>
        </Stack>
      </OnlyAdmin>
    </Stack>
  );
};

export default ConfigurationView;
