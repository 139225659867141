import React from "react";
import { Box, Button, InputAdornment } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormData } from "@features/auth/types/LoginFormData";
import {
  FIELD_MIN_LENGTH,
  REQUIRED_FIELD,
  VALID_EMAIL,
} from "@data/inputErrorTexts";
import TextFieldRHF from "@components/form/TextFieldRHF";
import { loginFormIsLoadingState } from "@features/auth/states/atoms";
import { useRecoilValue } from "recoil";
import useDoLogin from "@features/auth/hooks/useDoLogin";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LoginIcon from "@mui/icons-material/Login";
import HCaptchaRHF from "@components/form/HCaptchaRHF";

const LoginForm = () => {
  const loginFormIsLoading = useRecoilValue(loginFormIsLoadingState);
  const doLogin = useDoLogin();

  const defaultValues: LoginFormData = {
    email: "",
    password: "",
    captchaToken: undefined,
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(VALID_EMAIL).required(REQUIRED_FIELD),
    password: Yup.string().min(4, FIELD_MIN_LENGTH).required(REQUIRED_FIELD),
    captchaToken: Yup.string().required(REQUIRED_FIELD),
  });

  const { control, handleSubmit, resetField } = useForm<LoginFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (loginFormData: LoginFormData) => {
    doLogin(loginFormData, resetField);
  };

  return (
    <Box
      component="form"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <TextFieldRHF
        name="email"
        control={control}
        label="Email"
        type="email"
        margin="dense"
        disabled={loginFormIsLoading}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AlternateEmailIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextFieldRHF
        name="password"
        control={control}
        label="Senha"
        type="password"
        margin="dense"
        disabled={loginFormIsLoading}
        variant="outlined"
        sx={{ mb: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <VpnKeyIcon />
            </InputAdornment>
          ),
        }}
      />
      <HCaptchaRHF
        name="captchaToken"
        control={control}
        rootProps={{
          sx: {
            alignSelf: "flex-end",
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        disabled={loginFormIsLoading}
        sx={{ mt: 1, width: "fit-content", alignSelf: "flex-end" }}
        endIcon={<LoginIcon />}
      >
        Entrar
      </Button>
    </Box>
  );
};

export default LoginForm;
