import { useEffect } from "react";

import { loggedUserState } from "@features/auth";
import useDoLogout from "@features/dashboard/hooks/useDoLogout";
import { useRecoilState } from "recoil";
import { userApi } from "@features/user";

const useLoginCheck = () => {
  const doLogout = useDoLogout();
  const [loggedUser, setUserLogged] = useRecoilState(loggedUserState);

  const loginCheck = async () => {
    try {
      const user = await userApi.findMe();
      setUserLogged(user);
    } catch (e) {
      await doLogout();
    }
  };

  useEffect(() => {
    if (!loggedUser) loginCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  return { isLogged: !!loggedUser };
};

export default useLoginCheck;
