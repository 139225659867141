import { InputHTMLAttributes, forwardRef, ForwardedRef } from "react";
import { IMaskInput } from "react-imask";

type Props = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
} & InputHTMLAttributes<HTMLInputElement>;

const MaskedInputPhoneBr = (
  { name, onChange, ...rest }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <IMaskInput
      mask={[{ mask: "(00) 0000-0000" }, { mask: "(00) 00000-0000" }]}
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => {
        onChange({ target: { name: name, value } });
      }}
      unmask
      {...rest}
    />
  );
};

export default forwardRef(MaskedInputPhoneBr);
