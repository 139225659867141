import { atom } from "recoil";
import { PaginationData } from "@@types/PaginationData";
import { PaginateFilter } from "@@types/PaginateFilter";
import { User } from "@features/user";

export const listState = atom<PaginationData<User>>({
  key: "user/listState",
  default: {
    total: 0,
    data: [],
  },
});

export const listStatusState = atom<{
  isLoading: boolean;
  errorMessage?: string;
}>({
  key: "user/listStatusState",
  default: {
    isLoading: false,
    errorMessage: undefined,
  },
});

export const itemSelectedState = atom<User | undefined>({
  key: "user/itemSelectedState",
  default: undefined,
});

export const listFilterState = atom<Required<PaginateFilter>>({
  key: "user/listFilterState",
  default: {
    skip: 0,
    take: 30,
  },
});

export const formIsLoadingState = atom({
  key: "user/formIsLoadingState",
  default: false,
});

export const modalIsOpenState = atom({
  key: "user/modalIsOpenState",
  default: false,
});

export const profileFormIsLoadingState = atom({
  key: "user/profileFormIsLoadingState",
  default: false,
});
