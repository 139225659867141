import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Tooltip } from "@mui/material";

type Props = {
  invoicesSum: string;
};

const PaidInvoice = ({ invoicesSum }: Props) => {
  if (invoicesSum === "0.00") {
    return (
      <Tooltip title="Registro Zerado/Tudo Pago" placement="right" arrow>
        <TaskAltIcon color="success" />
      </Tooltip>
    );
  }

  return <></>;
};

export default PaidInvoice;
