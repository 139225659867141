import { Box } from "@mui/material";
import React from "react";
import CustomerTable from "@features/customer/components/list/CustomerTable";
import CustomerFilterBar from "@features/customer/components/ui/CustomerFilterBar";
import FormModal from "@components/ui/FormModal";
import CustomerForm from "@features/customer/components/form/CustomerForm";
import {
  formIsLoadingState,
  itemSelectedState,
  modalIsOpenState,
} from "@features/customer/states/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { InvoiceModal } from "@features/invoice";
import AsyncFormContainer from "@components/ui/AsyncFormContainer";

const CustomerView = () => {
  const [modalIsOpen, setModalIsOpen] = useRecoilState(modalIsOpenState);
  const formIsLoading = useRecoilValue(formIsLoadingState);
  const itemSelected = useRecoilValue(itemSelectedState);

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const modalTitle = itemSelected ? "Editar Cliente" : "Novo Cliente";

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CustomerFilterBar />
        <CustomerTable />
      </Box>
      <FormModal
        title={modalTitle}
        open={modalIsOpen}
        onClose={handleModalClose}
        isLoading={formIsLoading}
      >
        <AsyncFormContainer>
          <CustomerForm />
        </AsyncFormContainer>
      </FormModal>
      <InvoiceModal />
    </>
  );
};

export default CustomerView;
