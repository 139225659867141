import { useRecoilState, useRecoilValue } from "recoil";
import InvoiceTable from "@features/invoice/components/list/InvoiceTable";
import FormModal from "@components/ui/FormModal";
import {
  itemSelectedState,
  modalIsOpenState,
  formIsLoadingState,
} from "@features/invoice/states/atoms";
import useCurrentInvoiceType from "@features/invoice/hooks/useCurrentInvoiceType";
import { InvoiceType } from "@features/invoice/enums/InvoiceType";
import { yellow, lightGreen } from "@mui/material/colors";
import { useTheme } from "@mui/material";
import InvoiceForm from "@features/invoice/components/form/InvoiceForm";
import InvoiceInfo from "@features/invoice/components/ui/InvoiceInfo";

const InvoiceView = () => {
  const [modalIsOpen, setModalIsOpen] = useRecoilState(modalIsOpenState);
  const formIsLoading = useRecoilValue(formIsLoadingState);
  const itemSelected = useRecoilValue(itemSelectedState);
  const invoiceType = useCurrentInvoiceType();
  const theme = useTheme();

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const isUpdate = !!itemSelected;

  const appBarBackgroundColor =
    invoiceType === InvoiceType.PURCHASE ? yellow[500] : lightGreen[500];

  const appBarColor = theme.palette.getContrastText(appBarBackgroundColor);

  const invoiceTypeBrl =
    invoiceType === InvoiceType.PURCHASE ? "Compra" : "Pagamento";

  const modalTitle = `${
    isUpdate ? "Editar" : "Novo"
  } Registro de ${invoiceTypeBrl}`;

  return (
    <>
      <InvoiceInfo />
      <InvoiceTable />
      <FormModal
        title={modalTitle}
        open={modalIsOpen}
        onClose={handleModalClose}
        isLoading={formIsLoading}
        appBarBackgroundColor={appBarBackgroundColor}
        appBarColor={appBarColor}
      >
        <InvoiceForm />
      </FormModal>
    </>
  );
};

export default InvoiceView;
