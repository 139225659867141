import { PaginationData } from "@@types/PaginationData";
import { FilterCustomers } from "@features/customer/types/FilterCustomers";
import { Customer } from "@features/customer/types/Customer";
import httpClient from "@lib/httpClient";
import { CustomerFormData } from "@features/customer/types/CustomerFormData";
import { SelectOption } from "@@types/SelectOption";

async function findWithFilter(
  filterCustomers?: FilterCustomers
): Promise<PaginationData<Customer>> {
  const client = httpClient();

  const response = await client.get<PaginationData<Customer>>(
    "/customers/filter",
    {
      params: filterCustomers,
    }
  );

  response.data.data = response.data.data.map((customer) =>
    sanitizeCustomer(customer)
  );

  return response.data;
}

async function findOne(id: string): Promise<Customer> {
  const client = httpClient();

  const response = await client.get<Customer>(`/customers/${id}`);

  return sanitizeCustomer(response.data);
}

async function findAllSelectOptions(): Promise<SelectOption[]> {
  const client = httpClient();

  const response = await client.get<SelectOption[]>(
    "/customers/select-options"
  );

  return response.data;
}

async function create(customerFormData: CustomerFormData): Promise<Customer> {
  const client = httpClient();

  const response = await client.post<Customer>("/customers", customerFormData);

  return sanitizeCustomer(response.data);
}

async function update(
  id: string,
  customerFormData: CustomerFormData
): Promise<Customer> {
  const client = httpClient();

  const response = await client.patch<Customer>(
    `/customers/${id}`,
    customerFormData
  );

  return sanitizeCustomer(response.data);
}

async function remove(id: string): Promise<void> {
  const client = httpClient();
  await client.delete(`/customers/${id}`);
}

function sanitizeCustomer(customer: Customer): Customer {
  return {
    ...customer,
    createdAt: new Date(customer.createdAt),
    updatedAt: new Date(customer.updatedAt),
  };
}

const customerApi = {
  findWithFilter,
  findOne,
  create,
  update,
  remove,
  findAllSelectOptions,
};

export default customerApi;
