import ScreenContent from "@components/ui/ScreenContent";
import React from "react";
import { useRecoilValue } from "recoil";
import { loggedUserState } from "@features/auth";
import FlexLayer from "@components/ui/FlexLayer";
import { Container, Stack } from "@mui/material";
import ProfileForm from "@features/user/components/form/ProfileForm";

const ProfileView = () => {
  const loggedUser = useRecoilValue(loggedUserState);

  let pageTitle = `Perfil de ${loggedUser?.name}`;

  if (loggedUser?.isAdmin) {
    pageTitle += " ⭐";
  }

  return (
    <ScreenContent title={pageTitle}>
      <FlexLayer>
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "primary.dark" }}
        >
          <Container maxWidth="sm">
            <ProfileForm />
          </Container>
        </Stack>
      </FlexLayer>
    </ScreenContent>
  );
};

export default ProfileView;
