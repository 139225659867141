import {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
  SyntheticEvent,
} from "react";
import { Snackbar } from "@mui/material";

export interface SnackbarMessage {
  message: string;
  key: number;
}

type SnackbarContextValue = {
  show: (message: string) => void;
};

const snackbarContextInitialState: SnackbarContextValue = {
  show: () => null,
};

const SnackbarContext = createContext<SnackbarContextValue>(
  snackbarContextInitialState
);

type StoreProviderProps = {
  children: ReactNode;
  duration?: number;
};

export const SnackbarProvider = ({
  children,
  duration = 6000,
}: StoreProviderProps) => {
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(
    undefined
  );

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const show = (message: string) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  return (
    <SnackbarContext.Provider value={{ show }}>
      {children}
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        message={messageInfo ? messageInfo.message : undefined}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const snackbar = useContext(SnackbarContext);

  if (!snackbar)
    throw new Error("useSnackbar must be used within a SnackbarProvider.");

  return snackbar;
};
