import {
  Box,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Stack,
  IconButton,
} from "@mui/material";
import { Note } from "@features/note/types/Note";
import { formatDateToStringDateTime } from "@utils/dateUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

type Props = {
  note: Note;
  onClick?: () => void;
  onRemoveClick?: () => void;
};

const NoteCard = ({ note, onClick, onRemoveClick }: Props) => {
  return (
    <Card variant="outlined">
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box flexGrow={1} width={0}>
              <Typography variant="h6" color="text.secondary" noWrap>
                {note.title}
              </Typography>
            </Box>
            <IconButton
              onClick={(e) => {
                onRemoveClick?.();
                e.stopPropagation();
              }}
            >
              <DeleteIcon fontSize="small" sx={{ color: "error.main" }} />
            </IconButton>
          </Stack>
          <Stack direction="row" alignItems="center">
            <CalendarTodayIcon
              sx={{
                fontSize: 15,
                mr: 0.3,
                ml: -0.2,
                color: "text.secondary",
              }}
            />
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ mt: 0.4 }}
              noWrap
            >
              {formatDateToStringDateTime(note.createdAt)}
            </Typography>
          </Stack>
          <Box
            minHeight={200}
            maxHeight={200}
            sx={{ overflowY: "auto", mt: 1 }}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              whiteSpace="pre-wrap"
            >
              {note.description}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default NoteCard;
