import { atom } from "recoil";
import { Note } from "@features/note/types/Note";

export const listState = atom<Note[]>({
  key: "note/listState",
  default: [],
});

export const listStatusState = atom<{
  isLoading: boolean;
  errorMessage?: string;
}>({
  key: "note/listStatusState",
  default: {
    isLoading: false,
    errorMessage: undefined,
  },
});

export const itemSelectedState = atom<Note | undefined>({
  key: "note/itemSelectedState",
  default: undefined,
});

export const formIsLoadingState = atom({
  key: "note/formIsLoadingState",
  default: false,
});

export const modalIsOpenState = atom({
  key: "note/modalIsOpenState",
  default: false,
});
