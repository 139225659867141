import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  statusOfSumOfAllInvoicesState,
  sumOfAllInvoicesState,
} from "@features/metric/states/atoms";
import axiosErrorToString from "@utils/axiosErrorToString";
import metricApi from "@features/metric/services/metricApi";

type HookReturn = {
  value: number;
  isLoading: boolean;
  errorMessage?: string;
  valueRefresh: () => void;
};

const useSumOfAllInvoices = (): HookReturn => {
  const [value, setValue] = useRecoilState<number>(sumOfAllInvoicesState);
  const [valueStatus, setValueStatus] = useRecoilState(
    statusOfSumOfAllInvoicesState
  );

  const fetchValue = useCallback(async () => {
    try {
      setValueStatus({
        errorMessage: undefined,
        isLoading: true,
      });

      const valueResponse = await metricApi.getSumOfAllInvoices();

      setValue(valueResponse);
    } catch (e) {
      setValueStatus((oldListStatus) => ({
        ...oldListStatus,
        errorMessage: axiosErrorToString(e),
      }));
    } finally {
      setValueStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  }, [setValue, setValueStatus]);

  useEffect(() => {
    fetchValue();
  }, []);

  return {
    value,
    errorMessage: valueStatus.errorMessage,
    isLoading: valueStatus.isLoading,
    valueRefresh: fetchValue,
  };
};

export default useSumOfAllInvoices;
