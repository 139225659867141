import { useRecoilState } from "recoil";
import { PaginateFilter } from "@@types/PaginateFilter";
import { listFilterState } from "@features/user/states/atoms";

type HookReturn = {
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
};

const useUserListFilter = (): HookReturn => {
  const [listFilter, setListFilter] =
    useRecoilState<Required<PaginateFilter>>(listFilterState);

  const setPage = (newPage: number) => {
    setListFilter((oldFilter) => ({
      ...oldFilter,
      skip: newPage * oldFilter.take,
    }));
  };

  const setPageSize = (newPageSize: number) => {
    setListFilter((oldFilter) => ({
      ...oldFilter,
      take: newPageSize,
    }));
  };

  const pageSize = listFilter.take;
  const page = Math.floor(listFilter.skip / pageSize);

  return {
    setPageSize,
    setPage,
    page,
    pageSize,
  };
};

export default useUserListFilter;
