import { Box, Link, Typography } from "@mui/material";
import useSumOfAllInvoices from "@features/metric/hooks/useSumOfAllInvoices";
import { toCurrency } from "@utils/currencyUtils";

const SumOfAllInvoicesBar = () => {
  const { value, isLoading, valueRefresh, errorMessage } =
    useSumOfAllInvoices();

  const currencyValue = toCurrency(value);

  if (isLoading) {
    return (
      <Box
        px={2}
        py={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="subtitle1">Carregando Total...</Typography>
      </Box>
    );
  }

  if (errorMessage) {
    return (
      <Box
        px={2}
        py={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="subtitle1">
          <span>Erro: {errorMessage} </span>
          <Link sx={{ cursor: "pointer" }} onClick={() => valueRefresh()}>
            Tentar Novamente
          </Link>
        </Typography>
      </Box>
    );
  }

  return (
    <Box px={2} py={1} width="100%">
      <Typography variant="body2" noWrap>
        Soma de Todos os Registros do Sistema:
      </Typography>
      <Typography variant="subtitle1" noWrap fontWeight="bold">
        {currencyValue}
      </Typography>
    </Box>
  );
};

export default SumOfAllInvoicesBar;
