import { PaginationData } from "@@types/PaginationData";
import httpClient from "@lib/httpClient";
import { AuditWithUser } from "@features/audit/types/AuditWithUser";
import { FilterAudits } from "@features/audit/types/FilterAudits";

async function findWithFilter(
  filterAudits: FilterAudits
): Promise<PaginationData<AuditWithUser>> {
  const client = httpClient();

  const response = await client.get<PaginationData<AuditWithUser>>(
    "/audits/filter",
    {
      params: filterAudits,
    }
  );

  response.data.data = response.data.data.map((audit) => sanitizeAudit(audit));

  return response.data;
}

async function getSectionNames(): Promise<string[]> {
  const client = httpClient();

  const response = await client.get<string[]>("/audits/section-names");

  return response.data;
}

function sanitizeAudit(audit: AuditWithUser): AuditWithUser {
  return {
    ...audit,
    createdAt: new Date(audit.createdAt),
  };
}

const auditApi = {
  findWithFilter,
  getSectionNames,
};

export default auditApi;
