import { Invoice } from "@features/invoice/types/Invoice";
import { useRecoilState, useRecoilValue } from "recoil";
import { PaginationData } from "@@types/PaginationData";
import {
  listFilterState,
  listState,
  listStatusState,
} from "@features/invoice/states/atoms";
import { useCallback, useEffect } from "react";
import invoiceApi from "@features/invoice/services/invoiceApi";
import axiosErrorToString from "@utils/axiosErrorToString";
import { useCurrentCustomerId } from "@features/customer";

type HookReturn = {
  invoices: Invoice[];
  invoicesTotal: number;
  isLoading: boolean;
  errorMessage?: string;
  listRefresh: () => void;
};

const useInvoiceList = (): HookReturn => {
  const [list, setList] = useRecoilState<PaginationData<Invoice>>(listState);
  const listFilter = useRecoilValue(listFilterState);
  const [listStatus, setListStatus] = useRecoilState(listStatusState);
  const customerId = useCurrentCustomerId();

  const fetchCustomers = useCallback(async () => {
    try {
      setListStatus({
        errorMessage: undefined,
        isLoading: true,
      });

      const paginationInvoice = await invoiceApi.findByCustomerWithPaginate(
        customerId,
        listFilter
      );

      setList(paginationInvoice);
    } catch (e) {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        errorMessage: axiosErrorToString(e),
      }));
    } finally {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  }, [listFilter, setList, setListStatus]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  return {
    invoices: list.data,
    invoicesTotal: list.total,
    errorMessage: listStatus.errorMessage,
    isLoading: listStatus.isLoading,
    listRefresh: fetchCustomers,
  };
};

export default useInvoiceList;
