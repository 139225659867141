import { InvoiceType } from "@features/invoice/enums/InvoiceType";
import { useRecoilValue } from "recoil";
import {
  invoiceCreateTypeState,
  itemSelectedState,
} from "@features/invoice/states/atoms";
import { Invoice } from "@features/invoice/types/Invoice";

const useCurrentInvoiceType = (): InvoiceType => {
  const itemSelected = useRecoilValue<Invoice | undefined>(itemSelectedState);
  const invoiceCreateType = useRecoilValue<InvoiceType>(invoiceCreateTypeState);

  return itemSelected?.type ?? invoiceCreateType;
};

export default useCurrentInvoiceType;
