import { useRecoilState } from "recoil";
import { listState, listStatusState } from "@features/note/states/atoms";
import { useCallback, useEffect } from "react";
import axiosErrorToString from "@utils/axiosErrorToString";
import { Note } from "@features/note/types/Note";
import noteApi from "@features/note/services/noteApi";

type HookReturn = {
  notes: Note[];
  isLoading: boolean;
  errorMessage?: string;
  listRefresh: () => void;
};

const useNoteList = (): HookReturn => {
  const [list, setList] = useRecoilState<Note[]>(listState);
  const [listStatus, setListStatus] = useRecoilState(listStatusState);

  const fetchNotes = useCallback(async () => {
    try {
      setListStatus({
        errorMessage: undefined,
        isLoading: true,
      });

      const notes = await noteApi.findAll();

      setList(notes);
    } catch (e) {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        errorMessage: axiosErrorToString(e),
      }));
    } finally {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  }, [setList, setListStatus]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  return {
    notes: list,
    errorMessage: listStatus.errorMessage,
    isLoading: listStatus.isLoading,
    listRefresh: fetchNotes,
  };
};

export default useNoteList;
