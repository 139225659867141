import { Avatar, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { green, red } from "@mui/material/colors";

type Props = {
  canBuy: boolean;
};

const CustomerCanBuyAvatar = ({ canBuy }: Props) => {
  const backgroundColor = canBuy ? green[400] : red[300];
  const color = canBuy ? green[900] : red[900];

  const tooltipTitle = canBuy ? "Pode Comprar" : "Não Pode Comprar";

  return (
    <Tooltip title={tooltipTitle} placement="right" arrow>
      <Avatar
        sx={{
          backgroundColor,
          color,
        }}
      >
        {canBuy ? <PersonIcon /> : <PersonOffIcon />}
      </Avatar>
    </Tooltip>
  );
};

export default CustomerCanBuyAvatar;
