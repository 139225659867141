import { useConfirm } from "material-ui-confirm";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ContentContainer, {
  MenuItem,
} from "@features/dashboard/layouts/ContentContainer";
import useDoLogout from "@features/dashboard/hooks/useDoLogout";
import useLoginCheck from "@features/dashboard/hooks/useLoginCheck";
import FullScreenLazyLoad from "@components/ui/FullScreenLazyLoad";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

type Props = {
  children: JSX.Element;
};

const DashboardView = ({ children }: Props) => {
  const confirm = useConfirm();
  const doLogout = useDoLogout();
  const navigate = useNavigate();

  const { isLogged } = useLoginCheck();

  const handleExit = async () => {
    try {
      await confirm({
        title: "Deseja sair?",
        description: "Ao confirmar você será deslogado do app",
        confirmationText: "Sair",
        cancellationButtonProps: {
          color: "error",
        },
        cancellationText: "Cancelar",
      });
      doLogout();
    } catch (e) {}
  };

  const handleGoToProfile = () => {
    navigate("/panel/profile");
  };

  const appBarItems = useMemo<MenuItem[]>(
    () => [
      {
        label: "Início",
        icon: <HomeIcon />,
        to: "/panel/home",
      },
      {
        label: "Usuários",
        icon: <PeopleIcon />,
        to: "/panel/users",
        onlyAdmin: true,
      },
      {
        label: "Anotações",
        icon: <TextSnippetIcon />,
        to: "/panel/notes",
      },
      {
        label: "Métricas",
        icon: <AnalyticsIcon />,
        to: "/panel/metrics",
      },
      {
        label: "Auditoria",
        icon: <PlagiarismIcon />,
        to: "/panel/audits",
      },
    ],
    []
  );

  if (!isLogged) {
    return <FullScreenLazyLoad />;
  }

  return (
    <ContentContainer
      onExitClick={handleExit}
      onProfileClick={handleGoToProfile}
      menuItems={appBarItems}
    >
      {children}
    </ContentContainer>
  );
};

export default DashboardView;
