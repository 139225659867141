import { useRecoilState, useRecoilValue } from "recoil";
import { PaginationData } from "@@types/PaginationData";
import {
  listFilterState,
  listState,
  listStatusState,
} from "@features/customer/states/atoms";
import { useCallback, useEffect } from "react";
import customerApi from "@features/customer/services/customerApi";
import axiosErrorToString from "@utils/axiosErrorToString";
import { Customer } from "@features/customer/types/Customer";

type HookReturn = {
  customers: Customer[];
  customersTotal: number;
  isLoading: boolean;
  errorMessage?: string;
  listRefresh: () => void;
};

const useCustomerList = (): HookReturn => {
  const [list, setList] = useRecoilState<PaginationData<Customer>>(listState);
  const listFilter = useRecoilValue(listFilterState);
  const [listStatus, setListStatus] = useRecoilState(listStatusState);

  const fetchCustomers = useCallback(async () => {
    try {
      setListStatus({
        errorMessage: undefined,
        isLoading: true,
      });

      const filteredCustomer = await customerApi.findWithFilter(listFilter);

      setList(filteredCustomer);
    } catch (e) {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        errorMessage: axiosErrorToString(e),
      }));
    } finally {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  }, [listFilter, setList, setListStatus]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  return {
    customers: list.data,
    customersTotal: list.total,
    errorMessage: listStatus.errorMessage,
    isLoading: listStatus.isLoading,
    listRefresh: fetchCustomers,
  };
};

export default useCustomerList;
