import useCustomerList from "@features/customer/hooks/useCustomerList";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useMemo } from "react";
import { Customer } from "@features/customer/types/Customer";
import { formatDateToStringDate } from "@utils/dateUtils";
import useCustomerListFilter from "@features/customer/hooks/useCustomerListFilter";
import TableStatus from "@components/ui/TableStatus";
import FlexLayer from "@components/ui/FlexLayer";
import { useSetRecoilState } from "recoil";
import { itemOpenState } from "@features/customer/states/atoms";
import { useConfirm } from "material-ui-confirm";
import useRemoveCustomer from "@features/customer/hooks/useRemoveCustomer";
import useCustomerEditOpen from "@features/customer/hooks/useCustomerEditOpen";
import CustomerCanBuyAvatar from "@features/customer/components/ui/CustomerCanBuyAvatar";
import PaidInvoice from "@features/customer/components/ui/PaidInvoice";

const CustomerTable = () => {
  const confirm = useConfirm();
  const { customers, customersTotal, listRefresh, isLoading, errorMessage } =
    useCustomerList();
  const { setPage, setPageSize, pageSize, page } = useCustomerListFilter();
  const setItemOpen = useSetRecoilState(itemOpenState);
  const removeCustomer = useRemoveCustomer();
  const customerEditOpen = useCustomerEditOpen();

  const handleEdit = (customer: Customer) => {
    customerEditOpen(customer);
  };

  const handleOpen = (customer: Customer) => {
    setItemOpen(customer);
  };

  const handleRemove = async (customer: Customer) => {
    try {
      await confirm({
        title: `Deseja excluir o cliente ${customer.name}?`,
        cancellationText: "Cancelar",
        confirmationText: "Remover",
        confirmationButtonProps: {
          color: "error",
        },
      });

      await removeCustomer(customer.id);
    } catch (e) {
      // do nothing
    }
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: "",
        sortable: false,
        minWidth: 60,
        width: 60,
        renderCell: ({ row }: GridRenderCellParams<string, Customer>) => (
          <CustomerCanBuyAvatar canBuy={row.canBuy} />
        ),
      },
      {
        field: "name",
        headerName: "Nome",
        sortable: false,
        minWidth: 300,
      },
      {
        field: "nickname",
        headerName: "Apelido",
        minWidth: 200,
        sortable: false,
        valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
          value || "-",
      },
      {
        field: "invoicesSum",
        headerName: "Conta Zerada?",
        minWidth: 130,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }: GridRenderCellParams<string, Customer>) => (
          <PaidInvoice invoicesSum={value ?? "0.00"} />
        ),
      },
      {
        field: "description",
        headerName: "Descrição",
        flex: 1,
        minWidth: 120,
        sortable: false,
        valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
          value || "Vazio",
      },
      {
        field: "createdAt",
        headerName: "Criado Em",
        type: "dateTime",
        width: 100,
        sortable: false,
        headerAlign: "right",
        align: "right",
        valueFormatter: ({ value }) => formatDateToStringDate(value),
      },
      {
        field: "actions",
        type: "actions",
        width: 120,
        getActions: ({ row }: GridRowParams<Customer>) => [
          <GridActionsCellItem
            icon={<EditIcon sx={{ color: "info.main" }} />}
            label="Editar"
            onClick={() => handleEdit(row)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: "error.main" }} />}
            label="Delete"
            onClick={() => handleRemove(row)}
          />,
        ],
      },
    ],
    []
  );

  return (
    <TableStatus
      isLoading={isLoading}
      error={errorMessage}
      onTryAgain={listRefresh}
    >
      <FlexLayer>
        <DataGrid
          columns={columns}
          rows={customers}
          rowCount={customersTotal}
          page={page}
          onPageChange={(newPage: number) => setPage(newPage)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          pagination
          paginationMode="server"
          disableSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableVirtualization
          rowsPerPageOptions={[50, 100]}
          onRowClick={({ row }) => handleOpen(row)}
          sx={{
            border: 0,
            borderRadius: 0,
            "& .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
              {
                outline: "none",
              },
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
        />
      </FlexLayer>
    </TableStatus>
  );
};

export default CustomerTable;
