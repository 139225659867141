import { CustomerWithDaysOfLastRegister } from "@features/metric/types/CustomerWithDaysOfLastRegister";
import { CustomerWithSumOfInvoices } from "@features/metric/types/CustomerWithSumOfInvoices";
import httpClient from "@lib/httpClient";

async function findAllCustomerWithDaysOfLastRegister(): Promise<
  CustomerWithDaysOfLastRegister[]
> {
  const client = await httpClient();

  const response = await client.get<CustomerWithDaysOfLastRegister[]>(
    "/metrics/customer-with-days-of-last-register"
  );

  return response.data;
}

async function findAllCustomerWithSumOfInvoices(): Promise<
  CustomerWithSumOfInvoices[]
> {
  const client = await httpClient();

  const response = await client.get<CustomerWithSumOfInvoices[]>(
    "/metrics/customer-with-sum-of-invoices"
  );

  return response.data;
}

async function getSumOfAllInvoices(): Promise<number> {
  const client = await httpClient();

  const response = await client.get<number>("/metrics/sum-of-all-invoices");

  return response.data;
}

const metricApi = {
  findAllCustomerWithDaysOfLastRegister,
  findAllCustomerWithSumOfInvoices,
  getSumOfAllInvoices,
};

export default metricApi;
