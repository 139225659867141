import { ModalContent } from "@components/ui/FormModal";
import SubmitButton from "@components/ui/SubmitButton";
import { useForm } from "react-hook-form";
import { Box, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  formIsLoadingState,
  itemSelectedState,
} from "@features/user/states/atoms";
import { useRecoilValue } from "recoil";
import React, { useMemo } from "react";
import * as Yup from "yup";
import {
  FIELD_MIN_LENGTH,
  REQUIRED_FIELD,
  VALID_EMAIL,
} from "@data/inputErrorTexts";
import { emptyStringToNull } from "@utils/yupTransformers";
import TextFieldRHF from "@components/form/TextFieldRHF";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserFormData } from "@features/user/types/UserFormData";
import { User } from "@features/user/types/User";
import useCreateUser from "@features/user/hooks/useCreateUser";
import useUpdateUser from "@features/user/hooks/useUpdateUser";
import BooleanSelectRHF from "@components/form/BooleanSelectRHF";

const getDefaultValues = (user?: User): UserFormData => {
  return {
    name: user?.name ?? "",
    email: user?.email ?? "",
    password: null,
    isAdmin: user?.isAdmin ?? false,
  };
};

const getValidationSchema = (user?: User): Yup.AnyObjectSchema => {
  let validationDefault: Record<string, any> = {
    name: Yup.string().required(REQUIRED_FIELD),
    email: Yup.string().email(VALID_EMAIL).required(REQUIRED_FIELD),
    password: Yup.string().min(4, FIELD_MIN_LENGTH).required(REQUIRED_FIELD),
    isAdmin: Yup.boolean().required(REQUIRED_FIELD),
  };

  if (user) {
    validationDefault = {
      ...validationDefault,
      password: Yup.string()
        .min(4, FIELD_MIN_LENGTH)
        .nullable()
        .transform(emptyStringToNull),
    };
  }

  return Yup.object(validationDefault);
};

const UserForm = () => {
  const createUser = useCreateUser();
  const updateUser = useUpdateUser();

  const itemSelected = useRecoilValue(itemSelectedState);
  const formIsLoading = useRecoilValue(formIsLoadingState);

  const defaultValues = useMemo(
    () => getDefaultValues(itemSelected),
    [itemSelected]
  );

  const validationSchema = useMemo(
    () => getValidationSchema(itemSelected),
    [itemSelected]
  );

  const { handleSubmit, control, formState } = useForm<UserFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (userFormData: UserFormData) => {
    if (itemSelected) {
      return updateUser(itemSelected.id, userFormData);
    }

    createUser(userFormData);
  };

  const { isDirty } = formState;
  const isUpdate = !!itemSelected;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      <ModalContent
        actions={
          <SubmitButton disabled={formIsLoading || (!isDirty && isUpdate)}>
            Salvar
          </SubmitButton>
        }
      >
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid xs={6}>
            <TextFieldRHF
              name="name"
              control={control}
              label="Nome Completo"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TextFieldRHF
              name="email"
              control={control}
              label="Email"
              type="email"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <TextFieldRHF
              name="password"
              control={control}
              label="Senha"
              margin="dense"
              type="password"
              required={!isUpdate}
              disabled={formIsLoading}
              fullWidth
              inputProps={{
                autoComplete: "new-password",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <BooleanSelectRHF
              name="isAdmin"
              control={control}
              label="Administrador"
              margin="dense"
              required={!isUpdate}
              disabled={formIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VerifiedUserIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>
    </Box>
  );
};

export default UserForm;
