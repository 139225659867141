import { Tabs, Tab, Box } from "@mui/material";
import { SyntheticEvent, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MetricTab = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const tabValue = location.pathname;

  const handleTabChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: string) => {
      navigate(newValue, { replace: true });
    },
    []
  );

  const tabData: Record<string, string> = {
    "/panel/metrics/days-of-last-register": "Dias do Ultimo Registro",
    "/panel/metrics/sum-of-invoices": "Soma das Faturas",
  };

  const tabKeys = Object.keys(tabData);

  if (!tabKeys.includes(tabValue)) {
    return <Box width="100%" height={47} />;
  }

  const tabs = tabKeys.map((key) => (
    <Tab key={key} value={key} label={tabData[key]} />
  ));

  return (
    <Tabs onChange={handleTabChange} value={tabValue}>
      {tabs}
    </Tabs>
  );
};

export default MetricTab;
