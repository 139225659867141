import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import TableStatus from "@components/ui/TableStatus";
import React, { useMemo } from "react";
import useListCustomerWithSumOfInvoices from "@features/metric/hooks/useListCustomerWithSumOfInvoices";
import { Typography } from "@mui/material";
import { toCurrency } from "@utils/currencyUtils";
import { CustomerWithSumOfInvoices } from "@features/metric/types/CustomerWithSumOfInvoices";
import { useOpenCustomerById } from "@features/customer";

const TableSumOfInvoices = () => {
  const { list, isLoading, errorMessage, listRefresh } =
    useListCustomerWithSumOfInvoices();
  const openCustomerById = useOpenCustomerById();

  const handleOpenCustomer = (customerId: string) => {
    openCustomerById(customerId);
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "invoicesSum",
        headerName: "Soma das Faturas",
        sortable: false,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        valueFormatter: ({ value }) => toCurrency(value),
        renderCell: ({ formattedValue }) => (
          <Typography variant="h6">{formattedValue}</Typography>
        ),
      },
      {
        field: "name",
        headerName: "Nome",
        sortable: false,
        flex: 1,
        minWidth: 200,
      },
      {
        field: "nickname",
        headerName: "Apelido",
        sortable: false,
        flex: 1,
        minWidth: 200,
        valueFormatter: ({ value }) => value || "-",
      },
    ],
    []
  );

  return (
    <TableStatus
      isLoading={isLoading}
      error={errorMessage}
      onTryAgain={listRefresh}
    >
      <DataGrid
        columns={columns}
        rows={list}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        hideFooter
        onRowClick={({ row }: GridRowParams<CustomerWithSumOfInvoices>) => {
          handleOpenCustomer(row.id);
        }}
        sx={{
          border: 0,
          borderRadius: 0,
          "& .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
            {
              outline: "none",
            },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
      />
    </TableStatus>
  );
};

export default TableSumOfInvoices;
