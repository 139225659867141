import { ModalContent } from "@components/ui/FormModal";
import SubmitButton from "@components/ui/SubmitButton";
import { useForm } from "react-hook-form";
import { Box, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CustomerFormData } from "@features/customer/types/CustomerFormData";
import { Customer } from "@features/customer/types/Customer";
import {
  customerSelectOptionsState,
  formIsLoadingState,
  itemSelectedState,
} from "@features/customer/states/atoms";
import { useRecoilValue } from "recoil";
import React, { useMemo } from "react";
import * as Yup from "yup";
import { REQUIRED_FIELD, VALID_EMAIL } from "@data/inputErrorTexts";
import { emptyStringToUndefined } from "@utils/yupTransformers";
import TextFieldRHF from "@components/form/TextFieldRHF";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import { yupResolver } from "@hookform/resolvers/yup";
import useCreateCustomer from "@features/customer/hooks/useCreateCustomer";
import useUpdateCustomer from "@features/customer/hooks/useUpdateCustomer";
import {
  MaskedInputCpf,
  MaskedInputPhoneBr,
} from "@components/form/MaskedInput";
import AutocompleteRHF from "@components/form/AutocompleteRHF";
import BooleanSelectRHF from "@components/form/BooleanSelectRHF";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

const getDefaultValues = (customer?: Customer): CustomerFormData => {
  return {
    name: customer?.name ?? "",
    nickname: customer?.nickname ?? "",
    description: customer?.description ?? "",
    email: customer?.email ?? "",
    firstPhone: customer?.firstPhone ?? "",
    secondPhone: customer?.secondPhone ?? "",
    document: customer?.document ?? "",
    address: customer?.address ?? "",
    canBuy: customer?.canBuy ?? true,
    sponsorId: customer?.sponsorId ?? null,
  };
};

const getValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
    nickname: Yup.string().optional(),
    description: Yup.string().optional(),
    email: Yup.string()
      .email(VALID_EMAIL)
      .optional()
      .transform(emptyStringToUndefined),
    firstPhone: Yup.string().optional(),
    secondPhone: Yup.string().optional(),
    document: Yup.string().optional(),
    address: Yup.string().optional(),
    canBuy: Yup.boolean().required(REQUIRED_FIELD),
    sponsorId: Yup.string().optional().nullable(),
  });
};

const CustomerForm = () => {
  const createCustomer = useCreateCustomer();
  const updateCustomer = useUpdateCustomer();

  const itemSelected = useRecoilValue(itemSelectedState);
  const formIsLoading = useRecoilValue(formIsLoadingState);

  const customerSelectOptions = useRecoilValue(customerSelectOptionsState);

  const defaultValues = useMemo(
    () => getDefaultValues(itemSelected),
    [itemSelected]
  );

  const validationSchema = useMemo(() => getValidationSchema(), []);

  const { handleSubmit, control, formState } = useForm<CustomerFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (customerFormData: CustomerFormData) => {
    if (itemSelected) {
      return updateCustomer(itemSelected.id, customerFormData);
    }

    createCustomer(customerFormData);
  };

  const { isDirty } = formState;
  const isUpdate = !!itemSelected;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      <ModalContent
        actions={
          <SubmitButton disabled={formIsLoading || (!isDirty && isUpdate)}>
            Salvar
          </SubmitButton>
        }
      >
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid xs={6}>
            <TextFieldRHF
              name="name"
              control={control}
              label="Nome Completo"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TextFieldRHF
              name="nickname"
              control={control}
              label="Apelido (Nome Informal)"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TextFieldRHF
              name="firstPhone"
              control={control}
              label="Telefone/Celular (Principal)"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color="primary" />
                  </InputAdornment>
                ),
                inputComponent: MaskedInputPhoneBr as any,
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TextFieldRHF
              name="secondPhone"
              control={control}
              label="Telefone/Celular (Secundário)"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon color="primary" />
                  </InputAdornment>
                ),
                inputComponent: MaskedInputPhoneBr as any,
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TextFieldRHF
              name="document"
              control={control}
              label="CPF"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ContactEmergencyIcon color="primary" />
                  </InputAdornment>
                ),
                inputComponent: MaskedInputCpf as any,
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TextFieldRHF
              name="email"
              control={control}
              label="Email"
              type="email"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <TextFieldRHF
              name="address"
              control={control}
              label="Endereço"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <TextFieldRHF
              name="description"
              control={control}
              label="Descrição"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              multiline
              rows={2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ArticleIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <BooleanSelectRHF
              name="canBuy"
              control={control}
              label="Pode Comprar?"
              margin="dense"
              required={!isUpdate}
              disabled={formIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ReceiptLongIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <AutocompleteRHF
              name="sponsorId"
              control={control}
              disabled={formIsLoading}
              fullWidth
              options={customerSelectOptions}
              inputProps={{
                label: "Responsável",
                margin: "dense",
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon color="primary" />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>
    </Box>
  );
};

export default CustomerForm;
