import useAuditSectionNameList from "@features/audit/hooks/useAuditSectionNameList";
import { useState, MouseEvent, useMemo } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import useAuditListFilter from "@features/audit/hooks/useAuditListFilter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

type Option = {
  value: string | undefined;
  label: string;
};

function sectionNameListToOptions(sectionNameList: string[]) {
  sectionNameList.sort();

  const options = sectionNameList.map<Option>((sectionName) => ({
    value: sectionName,
    label: sectionName,
  }));

  options.unshift({ value: undefined, label: "Todas" });

  return options;
}

const SectionFilter = () => {
  const { sectionNameList, errorMessage, isLoading, tryAgain } =
    useAuditSectionNameList();
  const { sectionName, setSectionName } = useAuditListFilter();

  const options = useMemo(
    () => sectionNameListToOptions(sectionNameList),
    [sectionNameList]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemClick = (optionValue: Option["value"]) => {
    handleClose();
    setSectionName(optionValue);
  };

  const menuItems = options.map((option: Option, index: number) => {
    const key = `section-filter-${index}-${option.value ?? "all"}`;

    return (
      <MenuItem key={key} onClick={() => menuItemClick(option.value)}>
        {option.label}
      </MenuItem>
    );
  });

  const selectedOption = "Seção: " + (sectionName ?? "Todas");
  const menuLabel = isLoading ? "Carregando..." : selectedOption;

  if (errorMessage) {
    return (
      <Button onClick={tryAgain} color="error" variant="contained">
        Erro ao Listar as Seções, Tente Novamente
      </Button>
    );
  }

  return (
    <div>
      <Button
        onClick={handleClick}
        variant="outlined"
        startIcon={<FilterAltIcon />}
        disabled={isLoading}
      >
        {menuLabel}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems}
      </Menu>
    </div>
  );
};

export default SectionFilter;
