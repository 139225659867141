import ScreenContent from "@components/ui/ScreenContent";
import ButtonAdd from "@components/ui/ButtonAdd";
import UserTable from "@features/user/components/list/UserTable";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  formIsLoadingState,
  itemSelectedState,
  modalIsOpenState,
} from "@features/user/states/atoms";
import FormModal from "@components/ui/FormModal";
import React from "react";
import UserForm from "@features/user/components/form/UserForm";

const UserView = () => {
  const [itemSelected, setItemSelected] = useRecoilState(itemSelectedState);
  const [modalIsOpen, setModalIsOpen] = useRecoilState(modalIsOpenState);
  const formIsLoading = useRecoilValue(formIsLoadingState);

  const handleCloseFormModal = () => {
    setModalIsOpen(false);
  };

  const handleClickAdd = () => {
    setItemSelected(undefined);
    setModalIsOpen(true);
  };

  const isUpdate = !!itemSelected;

  const modalTitle = isUpdate ? "Editar Usuário" : "Criar Usuário";

  return (
    <>
      <ScreenContent
        title="Usuários"
        rightContent={
          <ButtonAdd onClick={handleClickAdd}>Criar Usuário</ButtonAdd>
        }
      >
        <UserTable />
      </ScreenContent>
      <FormModal
        title={modalTitle}
        open={modalIsOpen}
        onClose={handleCloseFormModal}
        isLoading={formIsLoading}
      >
        <UserForm />
      </FormModal>
    </>
  );
};

export default UserView;
