import {
  Card,
  CardContent,
  Collapse,
  Divider,
  InputAdornment,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { User } from "@features/user";
import { ProfileFormData } from "@features/user/types/ProfileFormData";
import * as Yup from "yup";
import {
  FIELD_MIN_LENGTH,
  REQUIRED_FIELD,
  VALID_EMAIL,
} from "@data/inputErrorTexts";
import { emptyStringToUndefined } from "@utils/yupTransformers";
import { useRecoilValue } from "recoil";
import { loggedUserState } from "@features/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "@mui/material/Unstable_Grid2";
import TextFieldRHF from "@components/form/TextFieldRHF";
import PersonIcon from "@mui/icons-material/Person";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SubmitButton from "@components/ui/SubmitButton";
import { profileFormIsLoadingState } from "@features/user/states/atoms";
import useUpdateProfile from "@features/user/hooks/useUpdateProfile";

const getDefaultValues = (user?: User): ProfileFormData => {
  return {
    name: user?.name === null ? undefined : user?.name,
    email: user?.email,
    password: "",
    currentPassword: "",
  };
};

const getValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object({
    name: Yup.string().optional().transform(emptyStringToUndefined),
    email: Yup.string()
      .email(VALID_EMAIL)
      .optional()
      .transform(emptyStringToUndefined),
    password: Yup.string()
      .min(4, FIELD_MIN_LENGTH)
      .optional()
      .transform(emptyStringToUndefined),
    currentPassword: Yup.string()
      .min(4, FIELD_MIN_LENGTH)
      .required(REQUIRED_FIELD),
  });
};

const ProfileForm = () => {
  const loggedUser = useRecoilValue(loggedUserState);
  const profileFormIsLoading = useRecoilValue(profileFormIsLoadingState);

  const updateProfile = useUpdateProfile();

  const defaultValues = useMemo(
    () => getDefaultValues(loggedUser),
    [loggedUser]
  );

  const validationSchema = useMemo(() => getValidationSchema(), []);

  const { handleSubmit, control, formState, resetField, reset } =
    useForm<ProfileFormData>({
      defaultValues,
      resolver: yupResolver(validationSchema),
    });

  const rebootForm = useCallback(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = useCallback(
    (profileFormData: ProfileFormData) => {
      updateProfile(profileFormData, resetField, rebootForm);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [rebootForm]
  );

  const { isDirty } = formState;

  return (
    <Card
      sx={{ width: "100%" }}
      elevation={3}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      {profileFormIsLoading && <LinearProgress />}
      <CardContent>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid xs={12}>
            <Typography variant="h6" mb={-1}>
              Dados Pessoais
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TextFieldRHF
              name="name"
              control={control}
              label="Nome Completo"
              margin="dense"
              disabled={profileFormIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <TextFieldRHF
              name="email"
              control={control}
              label="Email"
              type="email"
              margin="dense"
              fullWidth
              required
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <TextFieldRHF
              name="password"
              control={control}
              label="Nova Senha"
              margin="dense"
              type="password"
              disabled={profileFormIsLoading}
              fullWidth
              inputProps={{
                autoComplete: "new-password",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <Collapse in={isDirty}>
              <Divider flexItem />
              <TextFieldRHF
                name="currentPassword"
                control={control}
                label="Senha Atual"
                margin="dense"
                type="password"
                disabled={profileFormIsLoading}
                required
                fullWidth
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <Stack width="100%">
                <SubmitButton
                  sx={{ alignSelf: "flex-end" }}
                  disabled={profileFormIsLoading || !isDirty}
                >
                  Salvar Perfil
                </SubmitButton>
              </Stack>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProfileForm;
