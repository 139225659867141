import { InputHTMLAttributes, forwardRef, ForwardedRef } from "react";
import { IMaskInput } from "react-imask";

type Props = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
} & InputHTMLAttributes<HTMLInputElement>;

const MaskedInputPositiveInt = (
  { name, onChange, ...rest }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <IMaskInput
      mask={Number}
      signed
      min={1}
      max={100000}
      inputRef={ref}
      onAccept={(value: any) => {
        onChange({ target: { name: name, value } });
      }}
      {...rest}
    />
  );
};

export default forwardRef(MaskedInputPositiveInt);
