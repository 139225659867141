import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  AppBarItemProps,
} from "@features/dashboard/components/ui/AppBar";
import { useRecoilValue } from "recoil";
import { loggedUserState } from "@features/auth";

export type MenuItem = {
  label: string;
  to: string;
  icon: JSX.Element;
  onlyAdmin?: boolean;
};

type Props = {
  children: JSX.Element | JSX.Element[];
  onExitClick: () => void;
  onProfileClick: () => void;
  menuItems: MenuItem[];
};

const ContentContainer = ({
  children,
  menuItems,
  onExitClick,
  onProfileClick,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const loggedUser = useRecoilValue(loggedUserState);

  const appBarItemProps = useMemo<AppBarItemProps[]>(
    () =>
      menuItems.map<AppBarItemProps>((menuItem: MenuItem) => ({
        label: menuItem.label,
        icon: menuItem.icon,
        onClick: () => navigate(menuItem.to),
        active: location.pathname.includes(menuItem.to),
        show: !menuItem.onlyAdmin || loggedUser?.isAdmin,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuItems, location.pathname, loggedUser]
  );

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        position: "relative",
      }}
    >
      <AppBar
        items={appBarItemProps}
        onExitClick={onExitClick}
        onProfileClick={onProfileClick}
      />
      <Box sx={{ flex: 1, position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default ContentContainer;
