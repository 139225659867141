import { InvoiceType } from "@features/invoice/enums/InvoiceType";
import { Avatar, Tooltip } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { lightGreen, yellow } from "@mui/material/colors";

type Props = {
  invoiceType: InvoiceType;
};

const InvoiceTypeAvatar = ({ invoiceType }: Props) => {
  const isPurchase = invoiceType === InvoiceType.PURCHASE;
  const backgroundColor = isPurchase ? yellow[400] : lightGreen[400];
  const color = isPurchase ? yellow[900] : lightGreen[900];

  const tooltipTitle = isPurchase ? "Compra" : "Pagamento";

  return (
    <Tooltip title={tooltipTitle} placement="right" arrow>
      <Avatar
        sx={{
          backgroundColor,
          color,
        }}
      >
        {isPurchase ? <ShoppingBagIcon /> : <PaymentsIcon />}
      </Avatar>
    </Tooltip>
  );
};

export default InvoiceTypeAvatar;
