import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  listCustomerWithDaysOfLastRegisterState,
  listStatusCustomerWithDaysOfLastRegisterState,
} from "@features/metric/states/atoms";
import { CustomerWithDaysOfLastRegister } from "@features/metric/types/CustomerWithDaysOfLastRegister";
import axiosErrorToString from "@utils/axiosErrorToString";
import metricApi from "@features/metric/services/metricApi";

type HookReturn = {
  list: CustomerWithDaysOfLastRegister[];
  isLoading: boolean;
  errorMessage?: string;
  listRefresh: () => void;
};

const useListCustomerWithDaysOfLastRegister = (): HookReturn => {
  const [list, setList] = useRecoilState<CustomerWithDaysOfLastRegister[]>(
    listCustomerWithDaysOfLastRegisterState
  );
  const [listStatus, setListStatus] = useRecoilState(
    listStatusCustomerWithDaysOfLastRegisterState
  );

  const fetchList = useCallback(async () => {
    try {
      setListStatus({
        errorMessage: undefined,
        isLoading: true,
      });

      const listResponse =
        await metricApi.findAllCustomerWithDaysOfLastRegister();

      setList(listResponse);
    } catch (e) {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        errorMessage: axiosErrorToString(e),
      }));
    } finally {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  }, [setList, setListStatus]);

  useEffect(() => {
    fetchList();
  }, []);

  return {
    list,
    errorMessage: listStatus.errorMessage,
    isLoading: listStatus.isLoading,
    listRefresh: fetchList,
  };
};

export default useListCustomerWithDaysOfLastRegister;
