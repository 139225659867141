import { useSetRecoilState } from "recoil";
import { PaginationData } from "@@types/PaginationData";
import {
  formIsLoadingState,
  listState,
  modalIsOpenState,
} from "@features/user/states/atoms";
import { useSnackbar } from "@contexts/SnackbarContext";
import axiosErrorToString from "@utils/axiosErrorToString";
import { UserFormData } from "@features/user/types/UserFormData";
import userApi from "@features/user/services/userApi";
import { User } from "@features/user/types/User";

type HookReturn = (userFormData: UserFormData) => void;

const useCreateUser = (): HookReturn => {
  const setList = useSetRecoilState<PaginationData<User>>(listState);
  const setFormIsLoading = useSetRecoilState(formIsLoadingState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);
  const snackbar = useSnackbar();

  return async (userFormData: UserFormData) => {
    try {
      setFormIsLoading(true);

      const newUser = await userApi.create(userFormData);

      setList((oldList) => {
        return {
          total: oldList.total + 1,
          data: [newUser, ...oldList.data],
        };
      });

      setModalIsOpen(false);
      snackbar.show("Usuário criado com sucesso");
    } catch (e) {
      snackbar.show(
        axiosErrorToString(e, "Erro desconhecido ao criar usuário")
      );
    } finally {
      setFormIsLoading(false);
    }
  };
};

export default useCreateUser;
