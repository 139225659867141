import React, { ReactNode } from "react";
import { Box, Stack, Typography } from "@mui/material";

type Props = {
  children: ReactNode;
  title?: string;
  rightContent?: ReactNode;
};

function ScreenContent({ children, title, rightContent }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", backgroundColor: "grey.100" }}>
          <Stack
            direction="row"
            px={2}
            py={1.5}
            spacing={2}
            alignItems="center"
            minHeight="60.5px"
            maxHeight="60.5px"
            height="60.5px"
          >
            <Box sx={{ flex: 1 }}>
              <Typography letterSpacing="initial" variant="h5">
                {title}
              </Typography>
            </Box>
            <Stack direction="row" spacing={2}>
              {rightContent}
            </Stack>
          </Stack>
        </Box>
      </Box>
      {children}
    </Box>
  );
}

export default ScreenContent;
