import useListCustomerWithDaysOfLastRegister from "@features/metric/hooks/useListCustomerWithDaysOfLastRegister";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import TableStatus from "@components/ui/TableStatus";
import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { toDecimal } from "@utils/numberUtils";
import { CustomerWithDaysOfLastRegister } from "@features/metric/types/CustomerWithDaysOfLastRegister";
import { useOpenCustomerById } from "@features/customer";
import MetricInvoiceTypeChip from "@features/metric/components/ui/MetricInvoiceTypeChip";

const TableDaysOfLastRegister = () => {
  const { list, isLoading, errorMessage, listRefresh } =
    useListCustomerWithDaysOfLastRegister();
  const openCustomerById = useOpenCustomerById();

  const handleOpenCustomer = (customerId: string) => {
    openCustomerById(customerId);
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "invoiceDaysOfLastRegister",
        headerName: "Dias Atrás de Compra/Pagamento",
        sortable: false,
        minWidth: 260,
        headerAlign: "center",
        align: "center",
        valueFormatter: ({ value }) => toDecimal(value),
        renderCell: ({ formattedValue }) => (
          <Typography variant="h6">{formattedValue} dias</Typography>
        ),
      },
      {
        field: "invoiceType",
        headerName: "Compra/Pagamento",
        sortable: false,
        minWidth: 180,
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => (
          <MetricInvoiceTypeChip invoiceType={value} />
        ),
      },
      {
        field: "name",
        headerName: "Nome",
        sortable: false,
        flex: 1,
        minWidth: 200,
      },
      {
        field: "nickname",
        headerName: "Apelido",
        sortable: false,
        flex: 1,
        minWidth: 200,
        valueFormatter: ({ value }) => value || "-",
      },
    ],
    []
  );

  return (
    <TableStatus
      isLoading={isLoading}
      error={errorMessage}
      onTryAgain={listRefresh}
    >
      <DataGrid
        columns={columns}
        rows={list}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        hideFooter
        onRowClick={({
          row,
        }: GridRowParams<CustomerWithDaysOfLastRegister>) => {
          handleOpenCustomer(row.id);
        }}
        sx={{
          border: 0,
          borderRadius: 0,
          "& .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
            {
              outline: "none",
            },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
      />
    </TableStatus>
  );
};

export default TableDaysOfLastRegister;
