import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { loggedUserState } from "@features/auth";
import { userApi } from "@features/user";

function useDoAutoLogin() {
  const setLoggedUser = useSetRecoilState(loggedUserState);
  const navigate = useNavigate();

  const goToLogin = () => navigate("/login");
  const goToHome = () => navigate("/panel/home");

  const checkIfIsLogged = async () => {
    try {
      const user = await userApi.findMe();
      setLoggedUser(user);

      goToHome();
    } catch (e) {
      goToLogin();
    }
  };

  useEffect(() => {
    checkIfIsLogged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useDoAutoLogin;
