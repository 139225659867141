import { InputHTMLAttributes, forwardRef, ForwardedRef } from "react";
import { IMaskInput } from "react-imask";

type Props = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
} & InputHTMLAttributes<HTMLInputElement>;

const MaskedInputCpf = (
  { name, onChange, ...rest }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <IMaskInput
      mask="000.000.000-00"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => {
        onChange({ target: { name: name, value } });
      }}
      unmask
      {...rest}
    />
  );
};

export default forwardRef(MaskedInputCpf);
