import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Control, Path, useController } from "react-hook-form";
import { useMemo } from "react";

export type SelectOptionItem = {
  label: string;
  value: string;
};

type Props = Omit<AutocompleteProps<any, any, any, any>, "renderInput"> & {
  control: Control<any>;
  name: Path<any>;
  options: SelectOptionItem[];
  inputProps?: TextFieldProps;
};

const AutocompleteRHF = ({
  name,
  control,
  options,
  inputProps,
  ...rest
}: Props): JSX.Element => {
  const sanitizedOptions = useMemo(
    () => options.map((option) => option.value),
    [options]
  );

  const { field, fieldState } = useController({
    name,
    control,
  });

  const { value, onChange, onBlur } = field;

  return (
    <Autocomplete
      disablePortal
      options={sanitizedOptions}
      onBlur={onBlur}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      value={value}
      getOptionLabel={(option) => {
        return options.find((item) => item.value === option)?.label || "";
      }}
      {...rest}
      renderInput={(params) => (
        <TextField
          helperText={fieldState.error?.message}
          error={!!fieldState.error}
          variant="filled"
          {...inputProps}
          {...params}
        />
      )}
    />
  );
};

export default AutocompleteRHF;
