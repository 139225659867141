import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "./App";
import LoginPage from "@pages/LoginPage";
import DashboardLayout from "@layouts/DashboardLayout";
import MainPage from "@pages/MainPage";
import HomePage from "@pages/HomePage";
import ConfigurationPage from "@pages/ConfigurationPage";
import UserPage from "@pages/UserPage";
import ProfilePage from "@pages/ProfilePage";
import AuditPage from "@pages/AuditPage";
import MetricPage from "@pages/MetricPage";
import MetricDaysOfLastRegisterPage from "@pages/MetricDaysOfLastRegisterPage";
import MetricSumOfInvoicesPage from "@pages/MetricSumOfInvoicesPage";
import NotePage from "@pages/NotePage";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route element={<MainPage />} index />
          <Route path="login" element={<LoginPage />} />
          <Route path="panel" element={<DashboardLayout />}>
            <Route path="home" element={<HomePage />} />
            <Route path="config" element={<ConfigurationPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="users" element={<UserPage />} />
            <Route path="audits" element={<AuditPage />} />
            <Route path="notes" element={<NotePage />} />
            <Route path="metrics" element={<MetricPage />}>
              <Route
                path="days-of-last-register"
                element={<MetricDaysOfLastRegisterPage />}
              />
              <Route
                path="sum-of-invoices"
                element={<MetricSumOfInvoicesPage />}
              />
              <Route
                index
                element={
                  <Navigate to="/panel/metrics/days-of-last-register" replace />
                }
              />
              <Route
                path="*"
                element={
                  <Navigate to="/panel/metrics/days-of-last-register" replace />
                }
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
