import { itemOpenState } from "@features/customer";
import { useRecoilValue } from "recoil";

const useCurrentCustomerId = (): string => {
  const itemOpen = useRecoilValue(itemOpenState);

  return itemOpen?.id ?? "";
};

export default useCurrentCustomerId;
