import { atom } from "recoil";
import { PaginationData } from "@@types/PaginationData";
import { Customer } from "@features/customer/types/Customer";
import { FilterCustomers } from "@features/customer/types/FilterCustomers";
import { customerSelectOptionsQuery } from "@features/customer/states/selectors";

export const listState = atom<PaginationData<Customer>>({
  key: "customer/listState",
  default: {
    total: 0,
    data: [],
  },
});

export const listStatusState = atom<{
  isLoading: boolean;
  errorMessage?: string;
}>({
  key: "customer/listStatusState",
  default: {
    isLoading: false,
    errorMessage: undefined,
  },
});

export const itemSelectedState = atom<Customer | undefined>({
  key: "customer/itemSelectedState",
  default: undefined,
});

export const listFilterState = atom<FilterCustomers>({
  key: "customer/listFilterState",
  default: {
    skip: 0,
    take: 50,
    search: undefined,
    sort: "name",
    order: "asc",
  },
});

export const formIsLoadingState = atom<boolean>({
  key: "customer/formIsLoadingState",
  default: false,
});

export const modalIsOpenState = atom<boolean>({
  key: "customer/modalIsOpenState",
  default: false,
});

export const itemOpenState = atom<Customer | undefined>({
  key: "customer/itemOpenState",
  default: undefined,
});

export const customerSelectOptionsState = atom({
  key: "customer/selectOptionsState",
  default: customerSelectOptionsQuery,
});
