import { useRecoilState, useRecoilValue } from "recoil";
import { PaginationData } from "@@types/PaginationData";
import {
  listFilterState,
  listState,
  listStatusState,
} from "@features/audit/states/atoms";
import { useCallback, useEffect } from "react";
import axiosErrorToString from "@utils/axiosErrorToString";
import { AuditWithUser } from "@features/audit/types/AuditWithUser";
import auditApi from "@features/audit/services/auditApi";

type HookReturn = {
  audits: AuditWithUser[];
  auditsTotal: number;
  isLoading: boolean;
  errorMessage?: string;
  listRefresh: () => void;
};

const useAuditList = (): HookReturn => {
  const [list, setList] =
    useRecoilState<PaginationData<AuditWithUser>>(listState);
  const listFilter = useRecoilValue(listFilterState);
  const [listStatus, setListStatus] = useRecoilState(listStatusState);

  const fetchAudits = useCallback(async () => {
    try {
      setListStatus({
        errorMessage: undefined,
        isLoading: true,
      });

      const filteredAudits = await auditApi.findWithFilter(listFilter);

      setList(filteredAudits);
    } catch (e) {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        errorMessage: axiosErrorToString(e),
      }));
    } finally {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  }, [listFilter, setList, setListStatus]);

  useEffect(() => {
    fetchAudits();
  }, [fetchAudits]);

  return {
    audits: list.data,
    auditsTotal: list.total,
    errorMessage: listStatus.errorMessage,
    isLoading: listStatus.isLoading,
    listRefresh: fetchAudits,
  };
};

export default useAuditList;
