import React from "react";
import {
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import ModalTransition from "@components/ui/FormModal/ModalTransition";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  title?: string;
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
  appBarBackgroundColor?: string;
  appBarColor?: string;
};

const FormModal = ({
  title,
  open,
  onClose,
  isLoading,
  children,
  appBarBackgroundColor,
  appBarColor,
}: Props) => {
  const handleClose = () => {
    onClose && !isLoading && onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      TransitionComponent={ModalTransition}
      maxWidth="md"
      fullWidth
    >
      <AppBar
        position="sticky"
        sx={{ borderRadius: 0, backgroundColor: appBarBackgroundColor }}
      >
        <Toolbar>
          {title && (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                color: appBarColor,
                display: { xs: "none", sm: "block" },
              }}
            >
              {title}
            </Typography>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            sx={{ ml: 2, color: appBarColor }}
            onClick={handleClose}
            disabled={isLoading}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isLoading && <LinearProgress color="secondary" />}
      {children}
    </Dialog>
  );
};

export default FormModal;
