import { Box, CircularProgress } from "@mui/material";
import useDoAutoLogin from "@features/dashboard/hooks/useDoAutoLogin";

const MainView = () => {
  useDoAutoLogin();

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default MainView;
