import httpClient from "@lib/httpClient";

async function searchSync() {
  const client = httpClient();

  await client.post("/search-sync");
}

const configurationApi = {
  searchSync,
};

export default configurationApi;
