import { useSetRecoilState } from "recoil";
import { listState, listStatusState } from "@features/note/states/atoms";
import { useSnackbar } from "@contexts/SnackbarContext";
import axiosErrorToString from "@utils/axiosErrorToString";
import { Note } from "@features/note/types/Note";
import noteApi from "@features/note/services/noteApi";

type HookReturn = (noteId: string) => void;

const useRemoveNote = (): HookReturn => {
  const setList = useSetRecoilState<Note[]>(listState);
  const setListStatus = useSetRecoilState(listStatusState);

  const snackbar = useSnackbar();

  return async (noteId: string) => {
    try {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: true,
      }));

      await noteApi.remove(noteId);

      setList((oldList) => {
        return oldList.filter((note) => note.id !== noteId);
      });

      snackbar.show("Anotação removida com sucesso");
    } catch (e) {
      snackbar.show(
        axiosErrorToString(e, "Erro desconhecido ao remover a anotação")
      );
    } finally {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  };
};

export default useRemoveNote;
