import { Button, styled } from "@mui/material";
import { lightGreen } from "@mui/material/colors";

const PayButton = styled(Button)(({ theme }) => ({
  backgroundColor: lightGreen[500],
  color: theme.palette.getContrastText(lightGreen[500]),
  "&:hover": {
    backgroundColor: lightGreen[700],
  },
}));

export default PayButton;
