import React from "react";
import { Button, ButtonProps } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

function ButtonAdd(props: ButtonProps) {
  return (
    <Button
      variant="contained"
      color="success"
      endIcon={<AddIcon />}
      {...props}
    />
  );
}

export default ButtonAdd;
