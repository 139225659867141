import { Button, styled } from "@mui/material";
import { yellow } from "@mui/material/colors";

const BuyButton = styled(Button)(({ theme }) => ({
  backgroundColor: yellow[500],
  color: theme.palette.getContrastText(yellow[500]),
  "&:hover": {
    backgroundColor: yellow[700],
  },
}));

export default BuyButton;
