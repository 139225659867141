import * as Yup from "yup";
import { REQUIRED_FIELD } from "@data/inputErrorTexts";
import { Note } from "@features/note/types/Note";
import { NoteFormData } from "@features/note/types/NoteFormData";
import { useRecoilValue } from "recoil";
import {
  formIsLoadingState,
  itemSelectedState,
} from "@features/note/states/atoms";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Box, InputAdornment } from "@mui/material";
import { ModalContent } from "@components/ui/FormModal";
import SubmitButton from "@components/ui/SubmitButton";
import Grid from "@mui/material/Unstable_Grid2";
import TextFieldRHF from "@components/form/TextFieldRHF";
import TitleIcon from "@mui/icons-material/Title";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import useUpsertNote from "@features/note/hooks/useUpsertNote";

const getDefaultValues = (note?: Note): NoteFormData => {
  return {
    title: note?.title ?? "Nova Nota",
    description: note?.description ?? "",
  };
};

const getValidationSchema = (): Yup.AnyObjectSchema => {
  return Yup.object({
    title: Yup.string().required(REQUIRED_FIELD),
    description: Yup.string().optional(),
  });
};

const NoteForm = () => {
  const upsertNote = useUpsertNote();

  const itemSelected = useRecoilValue(itemSelectedState);
  const formIsLoading = useRecoilValue(formIsLoadingState);

  const defaultValues = useMemo(
    () => getDefaultValues(itemSelected),
    [itemSelected]
  );

  const validationSchema = useMemo(() => getValidationSchema(), []);

  const { handleSubmit, control, formState } = useForm<NoteFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (noteFormData: NoteFormData) => {
    if (itemSelected) {
      return upsertNote(noteFormData, itemSelected.id);
    }

    upsertNote(noteFormData);
  };

  const { isDirty } = formState;
  const isUpdate = !!itemSelected;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      <ModalContent
        actions={
          <SubmitButton disabled={formIsLoading || (!isDirty && isUpdate)}>
            Salvar
          </SubmitButton>
        }
      >
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid xs={12}>
            <TextFieldRHF
              name="title"
              control={control}
              label="Título"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              onFocus={(e) => e.target.select()}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TitleIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <TextFieldRHF
              name="description"
              control={control}
              label="Descrição"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              multiline
              rows={6}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TextSnippetIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>
    </Box>
  );
};

export default NoteForm;
