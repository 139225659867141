import { Note } from "@features/note/types/Note";
import httpClient from "@lib/httpClient";
import { NoteFormData } from "@features/note/types/NoteFormData";

async function findAll(): Promise<Note[]> {
  const client = httpClient();

  const response = await client.get<Note[]>("/notes");

  return response.data.map((note) => sanitizeNote(note));
}

async function upsert(
  noteFormData: NoteFormData,
  noteId?: string
): Promise<Note> {
  const client = httpClient();

  const response = await client.put<Note>("/notes", {
    ...noteFormData,
    noteId,
  });

  return sanitizeNote(response.data);
}

async function remove(id: string): Promise<void> {
  const client = httpClient();
  await client.delete(`/notes/${id}`);
}

function sanitizeNote(note: Note): Note {
  return {
    ...note,
    createdAt: new Date(note.createdAt),
    updatedAt: new Date(note.updatedAt),
  };
}

const noteApi = {
  findAll,
  upsert,
  remove,
};

export default noteApi;
