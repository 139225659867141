import { Box, Card, CardContent, LinearProgress } from "@mui/material";
import { useRecoilValue } from "recoil";
import { loginFormIsLoadingState } from "@features/auth/states/atoms";
import LoginForm from "@features/auth/components/form/LoginForm";

const LoginView = () => {
  const loginFormIsLoading = useRecoilValue(loginFormIsLoadingState);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(to right, #457fca, #5691c8)",
      }}
    >
      <Card elevation={8} sx={{ maxWidth: 400, width: "100%" }}>
        {loginFormIsLoading && <LinearProgress color="secondary" />}
        <CardContent>
          <LoginForm />
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginView;
