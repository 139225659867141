import { InvoiceType } from "@features/invoice";
import { memo, useMemo } from "react";
import { yellow, lightGreen } from "@mui/material/colors";
import { Chip, useTheme } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

function invoiceTypeToString(invoiceType: InvoiceType): string {
  switch (invoiceType) {
    case InvoiceType.PAYMENT:
      return "Pagamento";
    case InvoiceType.PURCHASE:
      return "Compra";
  }
}

function invoiceTypeToColor(invoiceType: InvoiceType): string {
  switch (invoiceType) {
    case InvoiceType.PAYMENT:
      return lightGreen[500];
    case InvoiceType.PURCHASE:
      return yellow[700];
  }
}

function invoiceTypeToIcon(invoiceType: InvoiceType): JSX.Element {
  switch (invoiceType) {
    case InvoiceType.PAYMENT:
      return <PaymentsIcon />;
    case InvoiceType.PURCHASE:
      return <ShoppingBagIcon />;
  }
}

type Props = {
  invoiceType: InvoiceType;
};

const MetricInvoiceTypeChip = ({ invoiceType }: Props) => {
  const theme = useTheme();

  const label = useMemo(() => invoiceTypeToString(invoiceType), [invoiceType]);
  const backgroundColor = useMemo(
    () => invoiceTypeToColor(invoiceType),
    [invoiceType]
  );
  const color = theme.palette.getContrastText(backgroundColor);
  const icon = useMemo(() => invoiceTypeToIcon(invoiceType), [invoiceType]);

  return (
    <Chip
      icon={icon}
      label={label}
      size="small"
      sx={{ minWidth: 110, backgroundColor, color }}
    />
  );
};

export default memo(MetricInvoiceTypeChip);
