import { CustomerFormData } from "@features/customer/types/CustomerFormData";
import customerApi from "@features/customer/services/customerApi";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  formIsLoadingState,
  itemOpenState,
  listState,
  modalIsOpenState,
} from "@features/customer/states/atoms";
import { useSnackbar } from "@contexts/SnackbarContext";
import { PaginationData } from "@@types/PaginationData";
import { Customer } from "@features/customer/types/Customer";
import axiosErrorToString from "@utils/axiosErrorToString";

type HookReturn = (
  customerId: string,
  customerFormData: CustomerFormData
) => void;

const useUpdateCustomer = (): HookReturn => {
  const setList = useSetRecoilState<PaginationData<Customer>>(listState);
  const setFormIsLoading = useSetRecoilState(formIsLoadingState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);
  const [itemOpen, setItemOpen] = useRecoilState(itemOpenState);
  const snackbar = useSnackbar();

  return async (customerId: string, customerFormData: CustomerFormData) => {
    try {
      setFormIsLoading(true);

      const updatedCustomer = await customerApi.update(
        customerId,
        customerFormData
      );

      setList((oldList) => {
        const index = oldList.data.findIndex(
          (operador) => operador.id === customerId
        );

        const newOperadorList = [
          ...oldList.data.slice(0, index),
          updatedCustomer,
          ...oldList.data.slice(index + 1),
        ];

        return {
          ...oldList,
          data: newOperadorList,
        };
      });

      // This update customer open
      if (itemOpen && itemOpen.id === customerId) {
        setItemOpen(updatedCustomer);
      }

      setModalIsOpen(false);
      snackbar.show("Cliente atualizado com sucesso");
    } catch (e) {
      snackbar.show(axiosErrorToString(e, "Erro desconhecido"));
    } finally {
      setFormIsLoading(false);
    }
  };
};

export default useUpdateCustomer;
