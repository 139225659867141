import { AuditAction } from "@features/audit/enums/AuditAction";
import { Chip, ChipProps } from "@mui/material";
import { memo, useMemo } from "react";

function actionToString(action: AuditAction): string {
  const labels: Record<AuditAction, string> = {
    [AuditAction.CREATE]: "Criado",
    [AuditAction.UPDATE]: "Atualizado",
    [AuditAction.DELETE]: "Excluído",
  };

  return labels[action] || "Desconhecido";
}

function actionToColor(action: AuditAction): ChipProps["color"] {
  const colors: Record<AuditAction, ChipProps["color"]> = {
    [AuditAction.CREATE]: "success",
    [AuditAction.UPDATE]: "info",
    [AuditAction.DELETE]: "error",
  };

  return colors[action] || "default";
}

type Props = {
  action: AuditAction;
};

const OperationChip = ({ action }: Props) => {
  const label = useMemo(() => actionToString(action), [action]);
  const color = useMemo(() => actionToColor(action), [action]);

  return (
    <Chip label={label} size="small" color={color} sx={{ minWidth: 80 }} />
  );
};

export default memo(OperationChip);
