import TableSumOfInvoices from "@features/metric/components/list/TableSumOfInvoices";
import SumOfAllInvoicesBar from "@features/metric/components/ui/SumOfAllInvoicesBar";
import { Divider, Stack } from "@mui/material";
import FlexLayer from "@components/ui/FlexLayer";

const MetricSumOfInvoicesView = () => {
  return (
    <Stack direction="column" flex={1}>
      <SumOfAllInvoicesBar />
      <Divider flexItem />
      <FlexLayer>
        <TableSumOfInvoices />
      </FlexLayer>
    </Stack>
  );
};

export default MetricSumOfInvoicesView;
