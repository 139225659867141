import Grid from "@mui/material/Unstable_Grid2";
import useNoteList from "@features/note/hooks/useNoteList";
import TableStatus from "@components/ui/TableStatus";
import FlexLayer from "@components/ui/FlexLayer";
import { Note } from "@features/note/types/Note";
import NoteCard from "@features/note/components/ui/NoteCard";
import { Box } from "@mui/material";
import { useSetRecoilState } from "recoil";
import {
  itemSelectedState,
  modalIsOpenState,
} from "@features/note/states/atoms";
import { useCallback } from "react";
import { useConfirm } from "material-ui-confirm";
import useRemoveNote from "@features/note/hooks/useRemoveNote";

const NoteGrid = () => {
  const { notes, isLoading, listRefresh, errorMessage } = useNoteList();
  const removeNote = useRemoveNote();

  const setItemSelected = useSetRecoilState(itemSelectedState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);

  const confirm = useConfirm();

  const handleUpdate = (note: Note) => {
    setItemSelected(note);
    setModalIsOpen(true);
  };

  const handleRemove = useCallback(
    async (note: Note) => {
      try {
        await confirm({
          title: `Deseja excluir a anotação ${note.title}?`,
          cancellationText: "Cancelar",
          confirmationText: "Deletar",
          confirmationButtonProps: {
            color: "error",
          },
        });

        removeNote(note.id);
      } catch (e) {
        // do nothing
      }
    },
    [confirm, removeNote]
  );

  const noteCards = notes.map((note: Note) => {
    const handleNoteCardClick = () => handleUpdate(note);
    const handleNoteDeleteClick = () => handleRemove(note);

    return (
      <Grid xs={12} sm={6} lg={4} key={note.id}>
        <NoteCard
          note={note}
          onClick={handleNoteCardClick}
          onRemoveClick={handleNoteDeleteClick}
        />
      </Grid>
    );
  });

  return (
    <TableStatus
      isLoading={isLoading}
      error={errorMessage}
      onTryAgain={listRefresh}
    >
      <FlexLayer>
        <Box width="100%" height="100%" p={2} sx={{ overflowY: "auto" }}>
          <Grid container spacing={2}>
            {noteCards}
          </Grid>
        </Box>
      </FlexLayer>
    </TableStatus>
  );
};

export default NoteGrid;
