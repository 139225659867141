import { LoginResponse } from "@features/auth/types/LoginResponse";
import { LoginFormData } from "@features/auth/types/LoginFormData";
import httpClient, { clearToken, setToken } from "@lib/httpClient";

async function login(loginFormData: LoginFormData): Promise<void> {
  const client = httpClient();

  const response = await client.post<LoginResponse>(
    "/auth/login",
    loginFormData
  );

  setToken(response.data.token);
}

function logout(): void {
  clearToken();
}

const authApi = {
  login,
  logout,
};

export default authApi;
