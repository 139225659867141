import { useSetRecoilState } from "recoil";
import { PaginationData } from "@@types/PaginationData";
import { useSnackbar } from "@contexts/SnackbarContext";
import axiosErrorToString from "@utils/axiosErrorToString";
import {
  lastChangeState,
  listState,
  listStatusState,
} from "@features/invoice/states/atoms";
import invoiceApi from "@features/invoice/services/invoiceApi";
import { Invoice } from "@features/invoice/types/Invoice";

type HookReturn = (invoiceId: string) => void;

const useRemoveInvoice = (): HookReturn => {
  const setList = useSetRecoilState<PaginationData<Invoice>>(listState);
  const setListStatus = useSetRecoilState(listStatusState);
  const setLastChange = useSetRecoilState(lastChangeState);

  const snackbar = useSnackbar();

  return async (invoiceId: string) => {
    try {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: true,
      }));

      await invoiceApi.remove(invoiceId);

      setList((oldList) => {
        return {
          total: oldList.total - 1,
          data: oldList.data.filter((invoice) => invoice.id !== invoiceId),
        };
      });

      setLastChange(Date.now());
      snackbar.show("Registro removido com sucesso");
    } catch (e) {
      snackbar.show(axiosErrorToString(e, "Erro desconhecido"));
    } finally {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  };
};

export default useRemoveInvoice;
