import { Customer } from "@features/customer/types/Customer";
import { useSetRecoilState } from "recoil";
import {
  itemSelectedState,
  modalIsOpenState,
} from "@features/customer/states/atoms";

type HookReturn = (customer: Customer) => void;

const useCustomerEditOpen = (): HookReturn => {
  const setItemSelected = useSetRecoilState(itemSelectedState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);

  return (customer: Customer) => {
    setItemSelected(customer);
    setModalIsOpen(true);
  };
};

export default useCustomerEditOpen;
