import React from "react";
import { loggedUserState } from "@features/auth";
import { useRecoilValue } from "recoil";

type Props = {
  children: React.ReactNode;
};

const OnlyAdmin = ({ children }: Props): JSX.Element => {
  const loggedUser = useRecoilValue(loggedUserState);

  if (!loggedUser?.isAdmin) {
    return <></>;
  }

  return children as JSX.Element;
};

export default OnlyAdmin;
