import { Box, ButtonBase, Tooltip } from "@mui/material";
import { cloneElement } from "react";

export type AppBarItemProps = {
  label: string;
  icon: JSX.Element;
  onClick?: () => void;
  active?: boolean;
  show?: boolean;
};

const AppBarItem = ({
  label,
  icon,
  onClick,
  active,
  show = true,
}: AppBarItemProps) => {
  return (
    <Tooltip title={label} placement="right" hidden={!show}>
      <Box
        sx={{
          width: 60,
          height: 60,
          backgroundColor: active ? "white" : "primary.dark",
          borderRadius: 3,
          overflow: "hidden",
        }}
      >
        <ButtonBase sx={{ width: "100%", height: "100%" }} onClick={onClick}>
          {cloneElement(icon, {
            fontSize: "large",
            sx: { color: active ? "primary.main" : "white" },
          })}
        </ButtonBase>
      </Box>
    </Tooltip>
  );
};

export default AppBarItem;
