import { NoteFormData } from "@features/note/types/NoteFormData";
import noteApi from "@features/note/services/noteApi";
import { useSnackbar } from "@contexts/SnackbarContext";
import { useSetRecoilState } from "recoil";
import {
  formIsLoadingState,
  listState,
  modalIsOpenState,
} from "@features/note/states/atoms";
import { Note } from "@features/note/types/Note";
import axiosErrorToString from "@utils/axiosErrorToString";

type HookReturn = (noteFormData: NoteFormData, noteId?: string) => void;

const useUpsertNote = (): HookReturn => {
  const setList = useSetRecoilState<Note[]>(listState);
  const setFormIsLoading = useSetRecoilState(formIsLoadingState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);
  const snackbar = useSnackbar();

  return async (noteFormData: NoteFormData, noteId?: string) => {
    try {
      setFormIsLoading(true);

      const upsertedNote = await noteApi.upsert(noteFormData, noteId);

      if (noteId) {
        setList((oldList) => {
          const index = oldList.findIndex((note) => note.id === noteId);

          return [
            ...oldList.slice(0, index),
            upsertedNote,
            ...oldList.slice(index + 1),
          ];
        });

        snackbar.show("Anotação atualizada com sucesso");
      } else {
        setList((oldList) => {
          return [upsertedNote, ...oldList];
        });

        snackbar.show("Anotação criada com sucesso");
      }

      setModalIsOpen(false);
    } catch (e) {
      snackbar.show(
        axiosErrorToString(e, "Erro desconhecido ao criar/atualizar a nota")
      );
    } finally {
      setFormIsLoading(false);
    }
  };
};

export default useUpsertNote;
