import HCaptcha from "@hcaptcha/react-hcaptcha";
import { HCAPTCHA_SITE_KEY } from "@data/configs";
import { PropsOf } from "@emotion/react";
import { Control, Path, useController } from "react-hook-form";
import { Box, FormHelperText, Stack } from "@mui/material";
import { forwardRef, LegacyRef } from "react";

type Props = Omit<
  PropsOf<typeof HCaptcha>,
  "sitekey" | "onVerify" | "onError" | "onExpire"
> & {
  control: Control<any>;
  name: Path<any>;
  rootProps?: PropsOf<typeof Stack>;
};

const HCaptchaRHF = (
  { name, control, rootProps, ...rest }: Props,
  ref: LegacyRef<HCaptcha>
) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const { onChange, onBlur } = field;

  const onVerify = (token: string) => onChange(token);
  const onProblem = () => onChange(undefined);

  const textError = fieldState.error ? (
    <FormHelperText sx={{ mt: -1 }} error>
      {fieldState.error?.message}
    </FormHelperText>
  ) : null;

  return (
    <Stack onBlur={onBlur} direction="column" {...rootProps}>
      <Box>
        <HCaptcha
          {...rest}
          sitekey={HCAPTCHA_SITE_KEY}
          onVerify={onVerify}
          onError={onProblem}
          onExpire={onProblem}
          ref={ref}
        />
      </Box>
      {textError}
    </Stack>
  );
};

export default forwardRef(HCaptchaRHF);
