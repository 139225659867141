import httpClient from "@lib/httpClient";
import { PaginateFilter } from "@@types/PaginateFilter";
import { PaginationData } from "@@types/PaginationData";
import { Invoice } from "@features/invoice/types/Invoice";
import { InvoiceFormData } from "@features/invoice/types/InvoiceFormData";
import { InvoiceType } from "@features/invoice/enums/InvoiceType";
import { omit } from "lodash";
import { InvoiceInfo } from "@features/invoice/types/InvoiceInfo";

async function findByCustomerWithPaginate(
  customerId: string,
  paginateFilter: PaginateFilter
): Promise<PaginationData<Invoice>> {
  const client = httpClient();

  const response = await client.get<PaginationData<Invoice>>(
    `/invoices/paginate/${customerId}`,
    {
      params: paginateFilter,
    }
  );

  response.data.data = response.data.data.map((invoice) =>
    sanitizeInvoice(invoice)
  );

  return response.data;
}

async function create(
  invoiceFormData: InvoiceFormData,
  invoiceType: InvoiceType,
  customerId: string
): Promise<Invoice> {
  const client = httpClient();

  const sanitizedInvoiceFormData = {
    ...omit(invoiceFormData, ["quantity"]),
    quantity: Number(invoiceFormData.quantity),
    type: invoiceType,
    customerId,
  };

  const response = await client.post<Invoice>(
    "/invoices",
    sanitizedInvoiceFormData
  );

  return sanitizeInvoice(response.data);
}

async function update(
  id: string,
  invoiceFormData: InvoiceFormData
): Promise<Invoice> {
  const client = httpClient();

  const sanitizedInvoiceFormData = {
    ...omit(invoiceFormData, ["quantity"]),
    quantity: Number(invoiceFormData?.quantity ?? "1"),
  };

  const response = await client.patch<Invoice>(
    `/invoices/${id}`,
    sanitizedInvoiceFormData
  );

  return sanitizeInvoice(response.data);
}

async function remove(id: string): Promise<void> {
  const client = httpClient();
  await client.delete(`/invoices/${id}`);
}

async function infoByCustomer(customerId: string): Promise<InvoiceInfo> {
  const client = httpClient();
  const response = await client.get<InvoiceInfo>(
    `/invoices/info/${customerId}`
  );
  return response.data;
}

async function countByCustomer(customerId: string): Promise<number> {
  const client = httpClient();
  const response = await client.get<number>(`/invoices/count/${customerId}`);
  return response.data;
}

function sanitizeInvoice(invoice: Invoice): Invoice {
  return {
    ...invoice,
    recordedAt: new Date(invoice.recordedAt),
    createdAt: new Date(invoice.createdAt),
    updatedAt: new Date(invoice.updatedAt),
  };
}

const invoiceApi = {
  findByCustomerWithPaginate,
  create,
  update,
  remove,
  infoByCustomer,
  countByCustomer,
};

export default invoiceApi;
