import ScreenContent from "@components/ui/ScreenContent";
import AuditTable from "@features/audit/components/list/AuditTable";
import SectionFilter from "@features/audit/components/ui/SectionFilter";

const AuditView = () => {
  return (
    <ScreenContent title="Auditoria" rightContent={<SectionFilter />}>
      <AuditTable />
    </ScreenContent>
  );
};

export default AuditView;
