import { Button, ButtonProps } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const SubmitButton = (buttonProps: ButtonProps) => {
  return (
    <Button
      type="submit"
      color="success"
      endIcon={<CheckIcon />}
      {...buttonProps}
    />
  );
};

export default SubmitButton;
