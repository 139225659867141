import axios, { AxiosInstance } from "axios";
import { API_URL } from "@data/configs";
import valueStorageEncrypted from "./valueStorageEncrypted";

let accessTokenValue = "";
const accessTokenKey = "accessToken";

function httpClient(): AxiosInstance {
  const token = getToken();

  return axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export default httpClient;

function getToken(): string {
  if (accessTokenValue === "") {
    accessTokenValue = valueStorageEncrypted.getItem(accessTokenKey) ?? "";
  }

  return accessTokenValue;
}

export function setToken(accessToken: string): void {
  accessTokenValue = accessToken;
  valueStorageEncrypted.setItem(accessTokenKey, accessToken);
}

export function clearToken(): void {
  accessTokenValue = "";
  valueStorageEncrypted.removeItem(accessTokenKey);
}
