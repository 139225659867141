import { atom } from "recoil";
import { PaginationData } from "@@types/PaginationData";
import { Invoice } from "@features/invoice/types/Invoice";
import { PaginateFilter } from "@@types/PaginateFilter";
import { InvoiceType } from "@features/invoice/enums/InvoiceType";

export const listState = atom<PaginationData<Invoice>>({
  key: "invoice/listState",
  default: {
    total: 0,
    data: [],
  },
});

export const listStatusState = atom<{
  isLoading: boolean;
  errorMessage?: string;
}>({
  key: "invoice/listStatusState",
  default: {
    isLoading: false,
    errorMessage: undefined,
  },
});

export const itemSelectedState = atom<Invoice | undefined>({
  key: "invoice/itemSelectedState",
  default: undefined,
});

export const invoiceCreateTypeState = atom<InvoiceType>({
  key: "invoice/createTypeState",
  default: InvoiceType.PAYMENT,
});

export const listFilterState = atom<Required<PaginateFilter>>({
  key: "invoice/listFilterState",
  default: {
    skip: 0,
    take: 50,
  },
});

export const formIsLoadingState = atom<boolean>({
  key: "invoice/formIsLoadingState",
  default: false,
});

export const modalIsOpenState = atom<boolean>({
  key: "invoice/modalIsOpenState",
  default: false,
});

export const lastChangeState = atom<number>({
  key: "invoice/lastChangeState",
  default: 0,
});
