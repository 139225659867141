import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "@contexts/SnackbarContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ptBRLocale from "date-fns/locale/pt-BR";

const App = () => {
  const theme = createTheme();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ptBRLocale}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <ConfirmProvider>
            <CssBaseline />
            <Outlet />
          </ConfirmProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
