import React from "react";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import ModalTransition from "@components/ui/FormModal/ModalTransition";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  title?: string;
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  actions?: React.ReactNode;
};

const FullScreenModal = ({
  title,
  open,
  onClose,
  children,
  actions,
}: Props) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionComponent={ModalTransition}
      fullScreen
    >
      <AppBar position="sticky" sx={{ borderRadius: 0 }}>
        <Toolbar>
          {title && (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              {title}
            </Typography>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {actions && (
            <Stack direction="row" spacing={1}>
              {actions}
            </Stack>
          )}
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            sx={{ ml: 2 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
};

export default FullScreenModal;
