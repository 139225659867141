import { TextField, TextFieldProps } from "@mui/material";
import { Control, Path, useController } from "react-hook-form";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";

type CustomDesktopDatePickerProps = Omit<
  DesktopDatePickerProps<any, any>,
  "renderInput" | "value" | "onChange"
>;

type Props = CustomDesktopDatePickerProps & {
  control: Control<any>;
  name: Path<any>;
  textFieldProps?: TextFieldProps;
};

const DatePickerRHF = <FieldValues,>({
  label,
  name,
  control,
  textFieldProps,
  ...rest
}: Props): JSX.Element => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const { onBlur, onChange, value } = field;

  return (
    <DesktopDatePicker
      label={label}
      value={value}
      onChange={onChange}
      renderInput={(params: TextFieldProps) => (
        <TextField
          helperText={fieldState.error?.message}
          error={!!fieldState.error}
          onBlur={onBlur}
          variant="filled"
          {...textFieldProps}
          {...params}
        />
      )}
      {...rest}
    />
  );
};

export default DatePickerRHF;
