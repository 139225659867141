import { atom } from "recoil";
import { User } from "@features/user";

export const loggedUserState = atom<User | undefined>({
  key: "auth/loggedUserState",
  default: undefined,
});

export const loginFormIsLoadingState = atom<boolean>({
  key: "auth/loginFormLoadState",
  default: false,
});
