import { useSetRecoilState } from "recoil";
import {
  formIsLoadingState,
  lastChangeState,
  listState,
  modalIsOpenState,
} from "@features/invoice/states/atoms";
import { useSnackbar } from "@contexts/SnackbarContext";
import { PaginationData } from "@@types/PaginationData";
import axiosErrorToString from "@utils/axiosErrorToString";
import { InvoiceFormData } from "@features/invoice/types/InvoiceFormData";
import invoiceApi from "@features/invoice/services/invoiceApi";
import { Invoice } from "@features/invoice/types/Invoice";
import useCurrentInvoiceType from "@features/invoice/hooks/useCurrentInvoiceType";
import { useCurrentCustomerId } from "@features/customer";

type HookReturn = (invoiceFormData: InvoiceFormData) => void;

const useCreateInvoice = (): HookReturn => {
  const invoiceType = useCurrentInvoiceType();
  const customerId = useCurrentCustomerId();

  const setList = useSetRecoilState<PaginationData<Invoice>>(listState);
  const setFormIsLoading = useSetRecoilState(formIsLoadingState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);
  const setLastChange = useSetRecoilState(lastChangeState);
  const snackbar = useSnackbar();

  return async (invoiceFormData: InvoiceFormData) => {
    try {
      setFormIsLoading(true);

      const newInvoice = await invoiceApi.create(
        invoiceFormData,
        invoiceType,
        customerId
      );

      setList((oldList) => {
        return {
          total: oldList.total + 1,
          data: [newInvoice, ...oldList.data],
        };
      });

      setLastChange(Date.now());
      setModalIsOpen(false);
      snackbar.show("Registro adicionado com sucesso");
    } catch (e) {
      snackbar.show(axiosErrorToString(e, "Erro desconhecido"));
    } finally {
      setFormIsLoading(false);
    }
  };
};

export default useCreateInvoice;
