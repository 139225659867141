import httpClient from "@lib/httpClient";
import { User } from "@features/user";
import { PaginateFilter } from "@@types/PaginateFilter";
import { PaginationData } from "@@types/PaginationData";
import { UserFormData } from "@features/user/types/UserFormData";
import { ProfileFormData } from "@features/user/types/ProfileFormData";

async function findMe(): Promise<User> {
  const client = httpClient();

  const response = await client.get<User>("/users/me");

  return sanitizeUser(response.data);
}

async function updateMe(profileFormData: ProfileFormData): Promise<User> {
  const client = httpClient();

  const response = await client.patch<User>("/users/me", profileFormData);

  return sanitizeUser(response.data);
}

async function findAll(): Promise<User[]> {
  const client = httpClient();

  const response = await client.get<User[]>("/users");
  return response.data.map((user) => sanitizeUser(user));
}

async function findWithPaginate(
  paginateFilter: PaginateFilter
): Promise<PaginationData<User>> {
  const client = httpClient();

  const response = await client.get<PaginationData<User>>("/users/paginate", {
    params: paginateFilter,
  });

  response.data.data = response.data.data.map((user) => sanitizeUser(user));

  return response.data;
}

async function create(userFormData: UserFormData): Promise<User> {
  const client = httpClient();

  const response = await client.post<User>("/users", userFormData);
  return sanitizeUser(response.data);
}

async function update(id: string, userFormData: UserFormData): Promise<User> {
  const client = httpClient();

  const response = await client.patch<User>(`/users/${id}`, userFormData);

  return sanitizeUser(response.data);
}

async function remove(id: string): Promise<void> {
  const client = httpClient();

  await client.delete(`/users/${id}`);
}

function sanitizeUser(user: User): User {
  return {
    ...user,
    createdAt: new Date(user.createdAt),
    updatedAt: new Date(user.updatedAt),
  };
}

const userApi = {
  findMe,
  updateMe,
  findWithPaginate,
  findAll,
  create,
  update,
  remove,
};

export default userApi;
