import { useRecoilState } from "recoil";
import { listFilterState } from "@features/audit/states/atoms";
import { FilterAudits } from "@features/audit/types/FilterAudits";

type HookReturn = {
  page: number;
  pageSize: number;
  sectionName: string | undefined;
  recordId: string | undefined;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setSectionName: (sectionName?: string) => void;
  setRecordId: (recordId?: string) => void;
};

const useAuditListFilter = (): HookReturn => {
  const [listFilter, setListFilter] =
    useRecoilState<FilterAudits>(listFilterState);

  const setPage = (newPage: number) => {
    setListFilter((oldFilter) => ({
      ...oldFilter,
      skip: newPage * oldFilter.take,
    }));
  };

  const setPageSize = (newPageSize: number) => {
    setListFilter((oldFilter) => ({
      ...oldFilter,
      take: newPageSize,
    }));
  };

  const setSectionName = (sectionName?: string) => {
    setListFilter((oldFilter) => ({
      ...oldFilter,
      sectionName,
    }));
  };

  const setRecordId = (recordId?: string) => {
    setListFilter((oldFilter) => ({
      ...oldFilter,
      recordId,
    }));
  };

  const pageSize = listFilter.take;
  const page = Math.floor(listFilter.skip / pageSize);

  return {
    setPageSize,
    setPage,
    setSectionName,
    setRecordId,
    page,
    pageSize,
    sectionName: listFilter.sectionName,
    recordId: listFilter.recordId,
  };
};

export default useAuditListFilter;
