function stringAvatar(name?: string): string {
  if (!name) return "";
  return (
    name
      ?.split(" ")
      .map((n) => n[0])
      .join("") || ""
  );
}

export default stringAvatar;
