import { UserView } from "@features/user";
import OnlyAdmin from "@components/ui/OnlyAdmin";

const UserPage = () => {
  return (
    <OnlyAdmin>
      <UserView />
    </OnlyAdmin>
  );
};

export default UserPage;
