import { useSetRecoilState } from "recoil";
import { PaginationData } from "@@types/PaginationData";
import { listState, listStatusState } from "@features/user/states/atoms";
import { useSnackbar } from "@contexts/SnackbarContext";
import axiosErrorToString from "@utils/axiosErrorToString";
import { User } from "@features/user/types/User";
import userApi from "../services/userApi";

type HookReturn = (userId: string) => void;

const useRemoveUser = (): HookReturn => {
  const setList = useSetRecoilState<PaginationData<User>>(listState);
  const setListStatus = useSetRecoilState(listStatusState);

  const snackbar = useSnackbar();

  return async (userId: string) => {
    try {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: true,
      }));

      await userApi.remove(userId);

      setList((oldList) => {
        return {
          total: oldList.total - 1,
          data: oldList.data.filter((user) => user.id !== userId),
        };
      });

      snackbar.show("Usuário removido com sucesso");
    } catch (e) {
      snackbar.show(
        axiosErrorToString(e, "Erro desconhecido ao remover usuário")
      );
    } finally {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  };
};

export default useRemoveUser;
