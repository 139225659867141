import { ChangeEvent } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { useController, Control, Path } from "react-hook-form";

export type InputTransformer = {
  input: (value: any) => any;
  output: (value: any) => any;
};

const inputTransformerDefaultValue: InputTransformer = {
  input: (value: any) => value,
  output: (value: any) => value,
};

type Props = TextFieldProps & {
  control: Control<any>;
  name: Path<any>;
  transformer?: InputTransformer;
};

const TextFieldRHF = ({
  name,
  control,
  transformer = inputTransformerDefaultValue,
  ...rest
}: Props): JSX.Element => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const { onBlur, onChange, value } = field;

  // O campo não aceita valores nulos
  const newValue = value === null ? "" : value;

  const transformedValue = transformer.input(newValue);
  const transformedHandleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(transformer.output(e.target.value));

  return (
    <TextField
      variant="filled"
      {...rest}
      value={transformedValue}
      onChange={transformedHandleChange}
      onBlur={onBlur}
      helperText={fieldState.error?.message}
      error={!!fieldState.error}
    />
  );
};

export default TextFieldRHF;
