import { UseFormResetField } from "react-hook-form";
import { AxiosError } from "axios";
import { LoginFormData } from "@features/auth/types/LoginFormData";
import { useSnackbar } from "@contexts/SnackbarContext";
import { useSetRecoilState } from "recoil";
import {
  loggedUserState,
  loginFormIsLoadingState,
} from "@features/auth/states/atoms";
import authApi from "@features/auth/services/authApi";
import { userApi } from "@features/user";
import { useNavigate } from "react-router-dom";

type HookReturn = (
  formData: LoginFormData,
  resetField: UseFormResetField<LoginFormData>
) => void;

const useDoLogin = (): HookReturn => {
  const setLoginFormIsLoading = useSetRecoilState(loginFormIsLoadingState);
  const setLoggedUser = useSetRecoilState(loggedUserState);

  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const goToPanel = () => navigate("/panel/home");

  return async (
    loginFormData: LoginFormData,
    resetField: UseFormResetField<LoginFormData>
  ) => {
    try {
      setLoginFormIsLoading(true);

      await authApi.login(loginFormData);
      const user = await userApi.findMe();

      setLoggedUser(user);
      setLoginFormIsLoading(false);

      goToPanel();
    } catch (e) {
      setLoginFormIsLoading(false);

      if (e instanceof AxiosError && e.code === AxiosError.ERR_BAD_REQUEST) {
        resetField("password");
        return snackbar.show("Email ou senha incorreto");
      }

      snackbar.show("Erro desconhecido, contate o administrador");
    }
  };
};

export default useDoLogin;
