import { InputHTMLAttributes, forwardRef, ForwardedRef } from "react";
import { IMaskInput } from "react-imask";

type Props = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
} & InputHTMLAttributes<HTMLInputElement>;

const MaskedInputCurrency = (
  { name, onChange, ...rest }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <IMaskInput
      mask={Number}
      scale={2}
      thousandsSeparator="."
      signed
      padFractionalZeros
      normalizeZeros
      radix=","
      mapToRadix={[".", ","]}
      min={0}
      max={1000000000}
      inputRef={ref}
      onAccept={(value: any) => {
        onChange({ target: { name: name, value } });
      }}
      unmask
      {...rest}
    />
  );
};

export default forwardRef(MaskedInputCurrency);
