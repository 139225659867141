import React from "react";
import { Control, Path, useController } from "react-hook-form";
import { MenuItem, TextField, TextFieldProps } from "@mui/material";

type Props = TextFieldProps & {
  control: Control<any>;
  name: Path<any>;
  trueLabel?: string;
  falseLabel?: string;
};

const BooleanSelectRHF = ({
  name,
  control,
  trueLabel = "Sim",
  falseLabel = "Não",
  ...rest
}: Props) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const { onBlur, onChange, value } = field;

  const customValue = !!value ? "1" : "0";
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event?.target?.value === "1");
  };

  return (
    <TextField
      variant="filled"
      {...rest}
      value={customValue}
      onChange={handleChange}
      onBlur={onBlur}
      helperText={fieldState.error?.message}
      error={!!fieldState.error}
      select
    >
      <MenuItem value="1">{trueLabel}</MenuItem>
      <MenuItem value="0">{falseLabel}</MenuItem>
    </TextField>
  );
};

export default BooleanSelectRHF;
