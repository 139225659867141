import { useConfirm } from "material-ui-confirm";
import useInvoiceListFilter from "@features/invoice/hooks/useInvoiceListFilter";
import useInvoiceList from "@features/invoice/hooks/useInvoiceList";
import { useMemo } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { formatDateToStringDateTime } from "@utils/dateUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import { Invoice } from "@features/invoice/types/Invoice";
import TableStatus from "@components/ui/TableStatus";
import FlexLayer from "@components/ui/FlexLayer";
import { useSetRecoilState } from "recoil";
import {
  itemSelectedState,
  modalIsOpenState,
} from "@features/invoice/states/atoms";
import useRemoveInvoice from "@features/invoice/hooks/useRemoveInvoice";
import InvoiceTypeAvatar from "@features/invoice/components/ui/InvoiceTypeAvatar";
import { useCurrentCustomer } from "@features/customer";
import { toCurrency } from "@utils/currencyUtils";

const InvoiceTable = () => {
  const confirm = useConfirm();
  const { invoices, invoicesTotal, listRefresh, isLoading, errorMessage } =
    useInvoiceList();
  const { setPage, setPageSize, pageSize, page } = useInvoiceListFilter();
  const setItemSelected = useSetRecoilState(itemSelectedState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);
  const removeInvoice = useRemoveInvoice();
  const customer = useCurrentCustomer();

  const handleEdit = (invoice: Invoice) => {
    setItemSelected(invoice);
    setModalIsOpen(true);
  };

  const handleRemove = async (invoice: Invoice) => {
    try {
      await confirm({
        title: `Deseja excluir este registro?`,
        cancellationText: "Cancelar",
        confirmationText: "Remover",
        confirmationButtonProps: {
          color: "error",
        },
      });

      removeInvoice(invoice.id);
    } catch (e) {
      // do nothing
    }
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: "",
        sortable: false,
        minWidth: 60,
        width: 60,
        renderCell: ({ row }: GridRenderCellParams<string, Invoice>) => (
          <InvoiceTypeAvatar invoiceType={row.type} />
        ),
      },
      {
        field: "quantity",
        headerName: "Qtd",
        sortable: false,
        headerAlign: "center",
        align: "center",
        valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
          `${value}x`,
        renderCell: ({
          formattedValue,
        }: GridRenderCellParams<number, Invoice>) => (
          <strong>{formattedValue}</strong>
        ),
      },
      {
        field: "label",
        headerName: "Produto",
        minWidth: 200,
        sortable: false,
        valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
          value || "-",
      },
      {
        field: "value",
        headerName: "Total",
        sortable: false,
        minWidth: 150,
        valueGetter: ({
          value,
          row,
        }: GridValueGetterParams<string, Invoice>) => {
          return row.quantity * Number(value); // Total calc
        },
        valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
          toCurrency(value),
        renderCell: ({
          formattedValue,
        }: GridRenderCellParams<string, Invoice>) => (
          <strong>{formattedValue}</strong>
        ),
      },
      {
        field: "boughtBy",
        headerName: "Comprado/Pago por",
        minWidth: 140,
        flex: 1,
        sortable: false,
        valueFormatter: ({ value }: GridValueFormatterParams<string>) => {
          return value === "" ? customer?.name : value;
        },
      },
      {
        field: "description",
        headerName: "Descrição",
        flex: 3,
        minWidth: 120,
        sortable: false,
        valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
          value || "Vazio",
      },
      {
        field: "recordedAt",
        headerName: "Gravado Em",
        type: "dateTime",
        width: 150,
        sortable: false,
        headerAlign: "right",
        align: "right",
        valueFormatter: ({ value }) => formatDateToStringDateTime(value),
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: ({ row }: GridRowParams<Invoice>) => [
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: "error.main" }} />}
            label="Delete"
            onClick={() => handleRemove(row)}
          />,
        ],
      },
    ],
    [customer]
  );

  return (
    <TableStatus
      isLoading={isLoading}
      error={errorMessage}
      onTryAgain={listRefresh}
    >
      <FlexLayer>
        <DataGrid
          columns={columns}
          rows={invoices}
          rowCount={invoicesTotal}
          page={page}
          onPageChange={(newPage: number) => setPage(newPage)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          pagination
          paginationMode="server"
          disableSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableVirtualization
          rowsPerPageOptions={[50, 100]}
          onRowClick={({ row }) => handleEdit(row)}
          sx={{
            border: 0,
            borderRadius: 0,
            "& .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
              {
                outline: "none",
              },
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
        />
      </FlexLayer>
    </TableStatus>
  );
};

export default InvoiceTable;
