import { InvoiceInfo } from "@features/invoice/types/InvoiceInfo";
import { useCallback, useEffect, useState } from "react";
import { useCurrentCustomerId } from "@features/customer";
import invoiceApi from "@features/invoice/services/invoiceApi";
import { useRecoilValue } from "recoil";
import { lastChangeState } from "@features/invoice/states/atoms";

type HookReturn = {
  isError: boolean;
  isLoading: boolean;
  info: InvoiceInfo;
};

const useInvoiceInfo = (): HookReturn => {
  const customerId = useCurrentCustomerId();
  // Used to force a reload when the invoices changes
  const lastChange = useRecoilValue(lastChangeState);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [info, setInfo] = useState<InvoiceInfo>({
    daysOfLastRegister: 0,
    remainingDebt: "0",
  });

  const fetchInvoiceInfo = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const invoiceInfo = await invoiceApi.infoByCustomer(customerId);

      setInfo(invoiceInfo);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [customerId]);

  useEffect(() => {
    fetchInvoiceInfo();
  }, [fetchInvoiceInfo, lastChange]);

  return {
    isError,
    isLoading,
    info,
  };
};

export default useInvoiceInfo;
