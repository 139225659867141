import { Box, Button, Divider, Stack } from "@mui/material";
import SearchBar from "@features/customer/components/form/SearchBar";
import TableFilterType, {
  CustomersOrderBy,
} from "@features/customer/components/ui/TableFilterType";
import React from "react";
import useCustomerListFilter from "@features/customer/hooks/useCustomerListFilter";
import {
  itemSelectedState,
  listFilterState,
  modalIsOpenState,
} from "@features/customer/states/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const CustomerFilterBar = () => {
  const { onSearch, onOrderBy } = useCustomerListFilter();
  const { sort } = useRecoilValue(listFilterState);
  const setItemSelected = useSetRecoilState(itemSelectedState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);

  const handleAddCustomer = () => {
    setItemSelected(undefined);
    setModalIsOpen(true);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", backgroundColor: "grey.100" }}>
        <Stack direction="row" px={2} py={1.5} spacing={2} alignItems="center">
          <Box sx={{ flex: 1 }}>
            <SearchBar onSearch={onSearch} />
          </Box>
          <Stack direction="row" spacing={2}>
            <TableFilterType
              sortBy={sort as CustomersOrderBy}
              setOrderFilter={onOrderBy}
            />
            <Divider orientation="vertical" flexItem />
            <Button
              variant="contained"
              color="success"
              endIcon={<PersonAddIcon />}
              onClick={handleAddCustomer}
            >
              Adicionar Cliente
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default CustomerFilterBar;
