import { Invoice } from "@features/invoice/types/Invoice";
import { InvoiceFormData } from "@features/invoice/types/InvoiceFormData";
import { InvoiceType } from "@features/invoice/enums/InvoiceType";
import * as Yup from "yup";
import { REQUIRED_FIELD } from "@data/inputErrorTexts";
import { useRecoilValue } from "recoil";
import {
  formIsLoadingState,
  itemSelectedState,
} from "@features/invoice/states/atoms";
import useCurrentInvoiceType from "@features/invoice/hooks/useCurrentInvoiceType";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SubmitButton from "@components/ui/SubmitButton";
import { Box, InputAdornment } from "@mui/material";
import { ModalContent } from "@components/ui/FormModal";
import Grid from "@mui/material/Unstable_Grid2";
import TextFieldRHF from "@components/form/TextFieldRHF";
import DatePickerRHF from "@components/form/DatePickerRHF";
import {
  MaskedInputCurrency,
  MaskedInputPositiveInt,
} from "@components/form/MaskedInput";
import useCreateInvoice from "@features/invoice/hooks/useCreateInvoice";
import useUpdateInvoice from "@features/invoice/hooks/useUpdateInvoice";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { mdiAccountSupervisor, mdiCurrencyBrl } from "@mdi/js";
import Icon from "@mdi/react";
import ArticleIcon from "@mui/icons-material/Article";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const getDefaultValues = (
  invoiceTypeState: InvoiceType,
  invoice?: Invoice
): InvoiceFormData => {
  return {
    label: invoice?.label ?? "",
    quantity: String(invoice?.quantity ?? 1),
    type: invoiceTypeState,
    value: invoice?.value ?? "0",
    description: invoice?.description ?? "",
    boughtBy: invoice?.boughtBy ?? "",
    recordedAt: invoice?.recordedAt ?? new Date(),
  };
};

const getValidationSchema = (invoiceType: InvoiceType): Yup.AnyObjectSchema => {
  const isPurchase = invoiceType === InvoiceType.PURCHASE;

  let baseValidation = {
    label: Yup.string().optional(),
    quantity: Yup.number().required(REQUIRED_FIELD),
    value: Yup.string().required(REQUIRED_FIELD),
    description: Yup.string().optional(),
    boughtBy: Yup.string().optional(),
    recordedAt: Yup.date().required(REQUIRED_FIELD),
  };

  if (isPurchase) {
    baseValidation = {
      ...baseValidation,
      label: Yup.string().required(REQUIRED_FIELD),
    };
  }

  return Yup.object(baseValidation);
};

const InvoiceForm = () => {
  const invoiceType = useCurrentInvoiceType();

  const itemSelected = useRecoilValue(itemSelectedState);
  const formIsLoading = useRecoilValue(formIsLoadingState);

  const createInvoice = useCreateInvoice();
  const updateInvoice = useUpdateInvoice();

  const defaultValues = useMemo(
    () => getDefaultValues(invoiceType, itemSelected),
    [itemSelected]
  );

  const validationSchema = useMemo(
    () => getValidationSchema(invoiceType),
    [invoiceType]
  );

  const { handleSubmit, control, formState } = useForm<InvoiceFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { isDirty } = formState;

  const onSubmit = (invoiceFormData: InvoiceFormData) => {
    if (itemSelected) {
      return updateInvoice(itemSelected.id, invoiceFormData);
    }

    createInvoice(invoiceFormData);
  };

  const isPurchase = invoiceType === InvoiceType.PURCHASE;
  const isUpdate = !!itemSelected;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      <ModalContent
        actions={
          <SubmitButton disabled={formIsLoading || (!isDirty && isUpdate)}>
            Salvar
          </SubmitButton>
        }
      >
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid xs={6}>
            <TextFieldRHF
              name="label"
              control={control}
              label="Produto"
              margin="dense"
              onFocus={(e) => e.target.select()}
              disabled={!isPurchase || formIsLoading}
              fullWidth
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Inventory2Icon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={4}>
            <TextFieldRHF
              name="value"
              control={control}
              label="Valor"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              required
              onFocus={(e) => e.target.select()}
              InputProps={{
                inputComponent: MaskedInputCurrency as any,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ color: "primary.main" }}
                  >
                    <Icon size={1} path={mdiCurrencyBrl} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={2}>
            <TextFieldRHF
              name="quantity"
              control={control}
              label="Quantidade"
              margin="dense"
              type="number"
              fullWidth
              disabled={!isPurchase || formIsLoading}
              required
              onFocus={(e) => e.target.select()}
              InputProps={{
                inputComponent: MaskedInputPositiveInt as any,
                startAdornment: (
                  <InputAdornment position="start">
                    <ShoppingCartIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TextFieldRHF
              name="boughtBy"
              control={control}
              label="Comprado por"
              margin="dense"
              disabled={formIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ color: "primary.main" }}
                  >
                    <Icon size={1} path={mdiAccountSupervisor} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={6}>
            <DatePickerRHF
              name="recordedAt"
              control={control}
              label="Data"
              disabled={formIsLoading}
              textFieldProps={{
                margin: "dense",
                fullWidth: true,
                required: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarMonthIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12}>
            <TextFieldRHF
              name="description"
              control={control}
              label="Descrição"
              margin="dense"
              multiline
              rows={3}
              disabled={formIsLoading}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ArticleIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>
    </Box>
  );
};

export default InvoiceForm;
