import React, { useCallback, useMemo } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useConfirm } from "material-ui-confirm";
import { useSetRecoilState } from "recoil";
import {
  itemSelectedState,
  modalIsOpenState,
} from "@features/user/states/atoms";
import { formatDateToStringDateTime } from "@utils/dateUtils";
import TableStatus from "@components/ui/TableStatus";
import FlexLayer from "@components/ui/FlexLayer";
import useUserList from "@features/user/hooks/useUserList";
import useRemoveUser from "@features/user/hooks/useRemoveUser";
import useUserListFilter from "@features/user/hooks/useUserListFilter";
import { User } from "@features/user/types/User";

const UserTable = () => {
  const { users, usersTotal, isLoading, errorMessage, listRefresh } =
    useUserList();
  const removeUser = useRemoveUser();
  const { setPage, setPageSize, pageSize, page } = useUserListFilter();
  const setItemSelected = useSetRecoilState(itemSelectedState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);

  const confirm = useConfirm();

  const handleRemove = useCallback(
    async (user: User) => {
      try {
        await confirm({
          title: `Deseja excluir o usuário ${user.name}?`,
          cancellationText: "Cancelar",
          confirmationText: "Deletar",
          confirmationButtonProps: {
            color: "error",
          },
        });

        removeUser(user.id);
      } catch (e) {
        // do nothing
      }
    },
    [confirm, removeUser]
  );

  const handleEdit = useCallback(
    (user: User) => {
      setItemSelected(user);
      setModalIsOpen(true);
    },
    [setItemSelected, setModalIsOpen]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        headerName: "Nome",
        sortable: false,
        flex: 1,
        minWidth: 200,
      },
      {
        field: "email",
        headerName: "Email",
        sortable: false,
        flex: 1,
        minWidth: 200,
      },
      {
        field: "isAdmin",
        headerName: "Admin?",
        type: "boolean",
        minWidth: 180,
        sortable: false,
      },
      {
        field: "createdAt",
        headerName: "Criado Em",
        type: "dateTime",
        width: 150,
        sortable: false,
        headerAlign: "right",
        align: "right",
        valueFormatter: ({ value }) => formatDateToStringDateTime(value),
      },
      {
        field: "actions",
        type: "actions",
        width: 120,
        getActions: ({ row }: GridRowParams<User>) => [
          <GridActionsCellItem
            icon={<EditIcon color="primary" />}
            label="Editar"
            onClick={() => handleEdit(row)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Deletar"
            onClick={() => handleRemove(row)}
          />,
        ],
      },
    ],
    [handleRemove, handleEdit]
  );

  return (
    <TableStatus
      isLoading={isLoading}
      error={errorMessage}
      onTryAgain={listRefresh}
    >
      <FlexLayer>
        <DataGrid
          columns={columns}
          rows={users}
          rowCount={usersTotal}
          page={page}
          onPageChange={(newPage: number) => setPage(newPage)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          pagination
          paginationMode="server"
          disableSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          rowsPerPageOptions={[30, 50, 100]}
        />
      </FlexLayer>
    </TableStatus>
  );
};

export default UserTable;
