import { atom } from "recoil";
import { CustomerWithDaysOfLastRegister } from "@features/metric/types/CustomerWithDaysOfLastRegister";
import { CustomerWithSumOfInvoices } from "@features/metric/types/CustomerWithSumOfInvoices";

export const listCustomerWithDaysOfLastRegisterState = atom<
  CustomerWithDaysOfLastRegister[]
>({
  key: "metric/listCustomerWithDaysOfLastRegisterState",
  default: [],
});

export const listStatusCustomerWithDaysOfLastRegisterState = atom<{
  isLoading: boolean;
  errorMessage?: string;
}>({
  key: "metric/listStatusCustomerWithDaysOfLastRegisterState",
  default: {
    isLoading: false,
    errorMessage: undefined,
  },
});

export const listCustomerWithSumOfInvoicesState = atom<
  CustomerWithSumOfInvoices[]
>({
  key: "metric/listCustomerWithSumOfInvoicesState",
  default: [],
});

export const listStatusCustomerWithSumOfInvoicesState = atom<{
  isLoading: boolean;
  errorMessage?: string;
}>({
  key: "metric/listStatusCustomerWithSumOfInvoicesState",
  default: {
    isLoading: false,
    errorMessage: undefined,
  },
});

export const sumOfAllInvoicesState = atom<number>({
  key: "metric/sumOfAllInvoicesState",
  default: 0,
});

export const statusOfSumOfAllInvoicesState = atom<{
  isLoading: boolean;
  errorMessage?: string;
}>({
  key: "metric/statusOfSumOfAllInvoicesState",
  default: {
    isLoading: false,
    errorMessage: undefined,
  },
});
