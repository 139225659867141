import React from "react";
import ScreenContent from "@components/ui/ScreenContent";
import ButtonAdd from "@components/ui/ButtonAdd";
import NoteGrid from "@features/note/components/list/NoteGrid";
import FormModal from "@components/ui/FormModal";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  formIsLoadingState,
  itemSelectedState,
  modalIsOpenState,
} from "@features/note/states/atoms";
import NoteForm from "@features/note/components/form/NoteForm";

const NoteView = () => {
  const [itemSelected, setItemSelected] = useRecoilState(itemSelectedState);
  const [modalIsOpen, setModalIsOpen] = useRecoilState(modalIsOpenState);
  const formIsLoading = useRecoilValue(formIsLoadingState);

  const handleCloseFormModal = () => {
    setModalIsOpen(false);
  };

  const handleClickAdd = () => {
    setItemSelected(undefined);
    setModalIsOpen(true);
  };
  const isUpdate = !!itemSelected;

  const modalTitle = isUpdate ? "Editar Anotação" : "Criar Anotação";

  return (
    <>
      <ScreenContent
        title="Anotações"
        rightContent={
          <ButtonAdd onClick={handleClickAdd}>Nova Anotação</ButtonAdd>
        }
      >
        <NoteGrid />
      </ScreenContent>
      <FormModal
        title={modalTitle}
        open={modalIsOpen}
        onClose={handleCloseFormModal}
        isLoading={formIsLoading}
      >
        <NoteForm />
      </FormModal>
    </>
  );
};

export default NoteView;
