import React, { useMemo } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { formatDateToStringDateTime } from "@utils/dateUtils";
import TableStatus from "@components/ui/TableStatus";
import FlexLayer from "@components/ui/FlexLayer";
import useAuditList from "@features/audit/hooks/useAuditList";
import useAuditListFilter from "@features/audit/hooks/useAuditListFilter";
import OperationBadge from "@features/audit/components/ui/OperationChip";
import { AuditAction } from "@features/audit/enums/AuditAction";

const UserTable = () => {
  const { audits, auditsTotal, isLoading, errorMessage, listRefresh } =
    useAuditList();
  const { setPage, setPageSize, pageSize, page } = useAuditListFilter();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "action",
        headerName: "Operação",
        sortable: false,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => (
          <OperationBadge action={value as AuditAction} />
        ),
      },
      {
        field: "sectionName",
        headerName: "Seção",
        sortable: false,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => <strong>{value}</strong>,
      },
      {
        field: "description",
        headerName: "Descrição",
        sortable: false,
        flex: 1,
        minWidth: 200,
      },
      {
        field: "userId",
        headerName: "Usuário",
        sortable: false,
        minWidth: 250,
        maxWidth: 300,
        valueGetter: ({ row }) => row.user?.name,
      },
      {
        field: "createdAt",
        headerName: "Criado Em",
        type: "dateTime",
        width: 150,
        sortable: false,
        headerAlign: "right",
        align: "right",
        valueFormatter: ({ value }) => formatDateToStringDateTime(value),
      },
    ],
    []
  );

  return (
    <TableStatus
      isLoading={isLoading}
      error={errorMessage}
      onTryAgain={listRefresh}
    >
      <FlexLayer>
        <DataGrid
          columns={columns}
          rows={audits}
          rowCount={auditsTotal}
          page={page}
          onPageChange={(newPage: number) => setPage(newPage)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          pagination
          paginationMode="server"
          disableSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          rowsPerPageOptions={[50, 100]}
        />
      </FlexLayer>
    </TableStatus>
  );
};

export default UserTable;
