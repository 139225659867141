import { PaginationData } from "@@types/PaginationData";
import { atom } from "recoil";
import { AuditWithUser } from "@features/audit/types/AuditWithUser";
import { FilterAudits } from "@features/audit/types/FilterAudits";

export const listState = atom<PaginationData<AuditWithUser>>({
  key: "audit/listState",
  default: {
    total: 0,
    data: [],
  },
});

export const listStatusState = atom<{
  isLoading: boolean;
  errorMessage?: string;
}>({
  key: "audit/listStatusState",
  default: {
    isLoading: false,
    errorMessage: undefined,
  },
});

export const listFilterState = atom<FilterAudits>({
  key: "audit/listFilterState",
  default: {
    skip: 0,
    take: 50,
    sectionName: undefined,
    recordId: undefined,
  },
});
