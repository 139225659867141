import { authApi, loggedUserState } from "@features/auth";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

type HookReturn = () => void;

const useDoLogout = (): HookReturn => {
  const setLoggedUser = useSetRecoilState(loggedUserState);
  const navigate = useNavigate();

  const goToLogin = () => navigate("/login");

  return () => {
    authApi.logout();
    setLoggedUser(undefined);
    goToLogin();
  };
};

export default useDoLogout;
