import { Avatar, Box, ButtonBase, Divider, Tooltip } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { memo } from "react";
import stringAvatar from "@utils/stringAvatar";
import AppBarItem, {
  AppBarItemProps,
} from "@features/dashboard/components/ui/AppBar/AppBarItem";
import { loggedUserState } from "@features/auth";
import { useRecoilValue } from "recoil";

export type AppBarProps = {
  items: AppBarItemProps[];
  onExitClick?: () => void;
  onProfileClick?: () => void;
};

const AppBar = ({ items, onExitClick, onProfileClick }: AppBarProps) => {
  const loggedUser = useRecoilValue(loggedUserState);

  const barItems = items.map(
    (appBarItemProps: AppBarItemProps, index: number) => (
      <AppBarItem
        key={`menu-item-${appBarItemProps.label}-${index}`}
        {...appBarItemProps}
      />
    )
  );

  const userName = loggedUser?.name ?? "Usuário";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "primary.main",
        width: 80,
        height: "100vh",
        position: "sticky",
        left: 0,
        zIndex: "appBar",
        boxShadow: 8,
        paddingTop: 1,
        paddingBottom: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 1.2,
          width: "100%",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Tooltip title={userName} placement="right">
          <ButtonBase
            sx={{ borderRadius: "100%", width: 60, height: 60, padding: 0 }}
            onClick={onProfileClick}
          >
            <Avatar
              sx={{
                width: 60,
                height: 60,
                backgroundColor: "white",
                color: "primary.main",
              }}
            >
              {stringAvatar(userName)}
            </Avatar>
          </ButtonBase>
        </Tooltip>
        <Divider sx={{ width: 60 }} />
        {barItems}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <AppBarItem label="Sair" icon={<ExitToApp />} onClick={onExitClick} />
      </Box>
    </Box>
  );
};

export default memo(AppBar);
