import { useSetRecoilState } from "recoil";
import { PaginationData } from "@@types/PaginationData";

import {
  formIsLoadingState,
  listState,
  modalIsOpenState,
} from "@features/user/states/atoms";
import { useSnackbar } from "@contexts/SnackbarContext";
import axiosErrorToString from "@utils/axiosErrorToString";
import { UserFormData } from "@features/user/types/UserFormData";
import { User } from "@features/user/types/User";
import userApi from "@features/user/services/userApi";

type HookReturn = (userId: string, userFormData: UserFormData) => void;

const useUpdateUser = (): HookReturn => {
  const setList = useSetRecoilState<PaginationData<User>>(listState);
  const setFormIsLoading = useSetRecoilState(formIsLoadingState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);
  const snackbar = useSnackbar();

  return async (userId: string, userFormData: UserFormData) => {
    try {
      setFormIsLoading(true);

      const updatedUser = await userApi.update(userId, userFormData);

      setList((oldList) => {
        const index = oldList.data.findIndex((user) => user.id === userId);

        const newUserList = [
          ...oldList.data.slice(0, index),
          updatedUser,
          ...oldList.data.slice(index + 1),
        ];

        return {
          ...oldList,
          data: newUserList,
        };
      });

      setModalIsOpen(false);
      snackbar.show("Usuário atualizado com sucesso");
    } catch (e) {
      snackbar.show(
        axiosErrorToString(e, "Erro desconhecido ao atualizar usuário")
      );
    } finally {
      setFormIsLoading(false);
    }
  };
};

export default useUpdateUser;
