import { CustomerFormData } from "@features/customer/types/CustomerFormData";
import customerApi from "@features/customer/services/customerApi";
import { useSetRecoilState } from "recoil";
import {
  formIsLoadingState,
  listState,
  modalIsOpenState,
} from "@features/customer/states/atoms";
import { useSnackbar } from "@contexts/SnackbarContext";
import { PaginationData } from "@@types/PaginationData";
import { Customer } from "@features/customer/types/Customer";
import axiosErrorToString from "@utils/axiosErrorToString";

type HookReturn = (customerFormData: CustomerFormData) => void;

const useCreateCustomer = (): HookReturn => {
  const setList = useSetRecoilState<PaginationData<Customer>>(listState);
  const setFormIsLoading = useSetRecoilState(formIsLoadingState);
  const setModalIsOpen = useSetRecoilState(modalIsOpenState);
  const snackbar = useSnackbar();

  return async (customerFormData: CustomerFormData) => {
    try {
      setFormIsLoading(true);

      const newCustomer = await customerApi.create(customerFormData);

      setList((oldList) => {
        return {
          total: oldList.total + 1,
          data: [newCustomer, ...oldList.data],
        };
      });

      setModalIsOpen(false);
      snackbar.show("Cliente adicionado com sucesso");
    } catch (e) {
      snackbar.show(axiosErrorToString(e, "Erro desconhecido"));
    } finally {
      setFormIsLoading(false);
    }
  };
};

export default useCreateCustomer;
