import { useEffect, useState } from "react";
import auditApi from "@features/audit/services/auditApi";
import { AxiosError } from "axios";

type HookReturn = {
  sectionNameList: string[];
  isLoading: boolean;
  errorMessage: string | undefined;
  tryAgain: () => void;
};

const useAuditSectionNameList = (): HookReturn => {
  const [sectionNameList, setSectionNameList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const fetchSectionName = async () => {
    try {
      setIsLoading(true);

      const sectionNameList = await auditApi.getSectionNames();

      setSectionNameList(sectionNameList);
    } catch (e) {
      if (e instanceof AxiosError) {
        return setErrorMessage(e?.response?.data.message);
      }

      setErrorMessage("Erro ao buscar lista de seções");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSectionName();
  }, []);

  return {
    sectionNameList,
    isLoading,
    errorMessage,
    tryAgain: fetchSectionName,
  };
};

export default useAuditSectionNameList;
