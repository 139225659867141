import { ProfileFormData } from "@features/user/types/ProfileFormData";
import { useSetRecoilState } from "recoil";
import { profileFormIsLoadingState } from "@features/user/states/atoms";
import { loggedUserState } from "@features/auth";
import { useSnackbar } from "@contexts/SnackbarContext";
import axiosErrorToString from "@utils/axiosErrorToString";
import userApi from "@features/user/services/userApi";
import { UseFormResetField } from "react-hook-form";
import { AxiosError } from "axios";

type HookReturn = (
  profileFormData: ProfileFormData,
  resetField: UseFormResetField<ProfileFormData>,
  rebootForm: () => void
) => void;

const useUpdateProfile = (): HookReturn => {
  const setProfileFormIsLoading = useSetRecoilState(profileFormIsLoadingState);
  const setLoggedUser = useSetRecoilState(loggedUserState);

  const snackbar = useSnackbar();

  return async (
    profileFormData: ProfileFormData,
    resetField: UseFormResetField<ProfileFormData>,
    rebootForm: () => void
  ) => {
    try {
      setProfileFormIsLoading(true);
      const updatedUser = await userApi.updateMe(profileFormData);

      setLoggedUser(updatedUser);
      rebootForm();
    } catch (e) {
      if (
        e instanceof AxiosError &&
        e.response?.data["message"] === "current password incorrect"
      ) {
        resetField("currentPassword");
        return snackbar.show("Senha atual incorreta");
      }

      snackbar.show(
        axiosErrorToString(e, "Erro desconhecido ao atualizar o perfil")
      );
    } finally {
      setProfileFormIsLoading(false);
    }
  };
};

export default useUpdateProfile;
