import { InputBase, Stack } from "@mui/material";
import { PersonSearch } from "@mui/icons-material";
import { memo, useEffect, useRef } from "react";

type Props = {
  onSearch: (search?: string) => void;
  searchDebounceTimeMs?: number;
};

const SearchBar = ({ onSearch, searchDebounceTimeMs = 300 }: Props) => {
  const searchTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(
    () => () => {
      if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);
    },
    []
  );

  const handleSearchChange = (search?: string) => {
    if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);

    searchTimeoutRef.current = setTimeout(() => {
      onSearch(search === "" ? undefined : search);
    }, searchDebounceTimeMs);
  };

  return (
    <Stack direction="row" width="100%" alignItems="center" spacing={1}>
      <PersonSearch sx={{ mb: "3px", color: "primary.dark" }} />
      <InputBase
        sx={{ flex: 1 }}
        placeholder="Buscar Clientes"
        onFocus={(e) => e.target.select()}
        onChange={(e) => handleSearchChange(e?.target?.value)}
      />
    </Stack>
  );
};

export default memo(SearchBar);
