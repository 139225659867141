import { useRecoilState } from "recoil";
import { listFilterState } from "@features/customer/states/atoms";
import { FilterCustomers } from "@features/customer/types/FilterCustomers";
import { Customer } from "@features/customer/types/Customer";
import { OrderByFilter } from "@@types/OrderByFilter";

type HookReturn = {
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  onSearch: (search?: string) => void;
  onOrderBy: (orderFilter: Required<OrderByFilter<Customer>>) => void;
};

const useCustomerListFilter = (): HookReturn => {
  const [listFilter, setListFilter] =
    useRecoilState<FilterCustomers>(listFilterState);

  const setPage = (newPage: number) => {
    setListFilter((oldFilter) => ({
      ...oldFilter,
      skip: newPage * oldFilter.take,
    }));
  };

  const setPageSize = (newPageSize: number) => {
    setListFilter((oldFilter) => ({
      ...oldFilter,
      take: newPageSize,
    }));
  };

  const onSearch = (search?: string) => {
    setListFilter((oldFilter) => ({
      ...oldFilter,
      search,
    }));
  };

  const onOrderBy = (orderFilter: Required<OrderByFilter<Customer>>) => {
    setListFilter((oldFilter) => ({
      ...oldFilter,
      ...orderFilter,
    }));
  };

  const pageSize = listFilter.take;
  const page = Math.floor(listFilter.skip / pageSize);

  return {
    setPageSize,
    setPage,
    page,
    pageSize,
    onSearch,
    onOrderBy,
  };
};

export default useCustomerListFilter;
