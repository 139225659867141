import { useSnackbar } from "@contexts/SnackbarContext";
import customerApi from "@features/customer/services/customerApi";
import { useSetRecoilState } from "recoil";
import { itemOpenState } from "@features/customer";
import { useNavigate } from "react-router-dom";

type HookReturn = (id: string) => void;

const useOpenCustomerById = (): HookReturn => {
  const setItemOpen = useSetRecoilState(itemOpenState);
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  return async (id: string) => {
    try {
      const customer = await customerApi.findOne(id);

      setItemOpen(customer);
      navigate("/panel/home");
    } catch (e) {
      snackbar.show("Problema ao abrir o cliente");
    }
  };
};

export default useOpenCustomerById;
