import { useSetRecoilState } from "recoil";
import { PaginationData } from "@@types/PaginationData";
import { useSnackbar } from "@contexts/SnackbarContext";
import axiosErrorToString from "@utils/axiosErrorToString";
import { Customer } from "@features/customer/types/Customer";
import customerApi from "@features/customer/services/customerApi";
import { invoiceApi } from "@features/invoice";
import { listState, listStatusState } from "@features/customer/states/atoms";
import { useConfirm } from "material-ui-confirm";
import pluralize from "pluralize";

type HookReturn = (customerId: string) => void;

const useRemoveCustomer = (): HookReturn => {
  const setList = useSetRecoilState<PaginationData<Customer>>(listState);
  const setListStatus = useSetRecoilState(listStatusState);
  const confirm = useConfirm();
  const snackbar = useSnackbar();

  const showConfirm = async (customerId: string) => {
    try {
      const customerInvoicesQuantity = await invoiceApi.countByCustomer(
        customerId
      );

      if (customerInvoicesQuantity > 0) {
        const registerWord = pluralize(
          "registro",
          customerInvoicesQuantity,
          true
        );

        await confirm({
          title: "Atenção Ação Irreversível ⚠️",
          description: `Este cliente possui ${registerWord}, deseja realmente excluir?`,
          confirmationText: `Remover com ${registerWord}`,
          cancellationText: "Cancelar",
          confirmationButtonProps: {
            color: "error",
          },
        });
      }

      return true;
    } catch (e) {
      if (e) {
        snackbar.show(axiosErrorToString(e, "Erro desconhecido"));
      }

      return false;
    }
  };

  return async (customerId: string) => {
    const confirmResult = await showConfirm(customerId);

    if (!confirmResult) return;

    try {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: true,
      }));

      await customerApi.remove(customerId);

      setList((oldList) => {
        return {
          total: oldList.total - 1,
          data: oldList.data.filter((customer) => customer.id !== customerId),
        };
      });

      snackbar.show("Cliente removido com sucesso");
    } catch (e) {
      snackbar.show(axiosErrorToString(e, "Erro desconhecido"));
    } finally {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  };
};

export default useRemoveCustomer;
