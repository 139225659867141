import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  listCustomerWithSumOfInvoicesState,
  listStatusCustomerWithSumOfInvoicesState,
} from "@features/metric/states/atoms";
import axiosErrorToString from "@utils/axiosErrorToString";
import metricApi from "@features/metric/services/metricApi";
import { CustomerWithSumOfInvoices } from "@features/metric/types/CustomerWithSumOfInvoices";

type HookReturn = {
  list: CustomerWithSumOfInvoices[];
  isLoading: boolean;
  errorMessage?: string;
  listRefresh: () => void;
};

const useListCustomerWithSumOfInvoices = (): HookReturn => {
  const [list, setList] = useRecoilState<CustomerWithSumOfInvoices[]>(
    listCustomerWithSumOfInvoicesState
  );
  const [listStatus, setListStatus] = useRecoilState(
    listStatusCustomerWithSumOfInvoicesState
  );

  const fetchList = useCallback(async () => {
    try {
      setListStatus({
        errorMessage: undefined,
        isLoading: true,
      });

      const listResponse = await metricApi.findAllCustomerWithSumOfInvoices();

      setList(listResponse);
    } catch (e) {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        errorMessage: axiosErrorToString(e),
      }));
    } finally {
      setListStatus((oldListStatus) => ({
        ...oldListStatus,
        isLoading: false,
      }));
    }
  }, [setList, setListStatus]);

  useEffect(() => {
    fetchList();
  }, []);

  return {
    list,
    errorMessage: listStatus.errorMessage,
    isLoading: listStatus.isLoading,
    listRefresh: fetchList,
  };
};

export default useListCustomerWithSumOfInvoices;
