import { Avatar, Stack, StackProps, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

type Props = {
  color?: Record<keyof typeof blue, string>;
  title: string;
  value: string;
  icon: JSX.Element;
  sx?: StackProps["sx"];
};

const InfoItem = ({ title, value, icon, sx, color = blue }: Props) => {
  return (
    <Stack direction="row" sx={{ ...sx }}>
      <Avatar sx={{ backgroundColor: color["400"], color: color["900"] }}>
        {icon}
      </Avatar>
      <Stack ml={1} justifyContent="center">
        <Typography variant="caption" fontWeight="bold" lineHeight="unset">
          {title}
        </Typography>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ color: color["900"] }}
          mt={-0.5}
          lineHeight="unset"
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default InfoItem;
